import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const UnAuthGuard = ({ component: Component, ...rest }) => {
  const isSignedIn = useSelector(state => state.persist.isSignedIn);
  const isAuthenticated = isSignedIn;

  return (
      <Route {...rest} render={props => isAuthenticated === false ?
          (<Component {...props} />) :
          (<Redirect to={{ pathname: "/vendor/dashboard", state: { from: props.location } }} />)}
      />
  );
};

export default UnAuthGuard;
