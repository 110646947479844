import * as types from '../actions/_types';

const initialState = {
    open_ticket:{}, 
    close_ticket:{}, 
};

export default function ticket(state = initialState, action) {
    // console.log('ticket ' ,action.payload)
    if(action.type == '[USER] SAVE_LOGIN_USER_DETAIL'){
        // console.log('my persist called ' ,action)
    
    }
  switch (action.type) {
    
    case types.SAVE_OPEN_TICKET:
      return { ...state,open_ticket: action.payload };  
    case types.SAVE_OPEN_TICKET:
      return { ...state,close_ticket: action.payload };  
    default:
      return state;
  }
}

