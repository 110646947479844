import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { networkDetail } from '../../../redux/actions/dashboard.action';

const Network = () => {
    const network = useSelector(state => state.kycData.network);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(networkDetail())
    }, [])
    
    const networkData = () =>{
        return (
            network?.networks &&  network.networks.length > 0 ?
            network.networks.map((row,i) => {
                    return(<tr>
                        <th scope="row">{i+1}</th>
                        <td>{row.CoinName}</td> 
                        <td>{row.Network}</td>
                    </tr>);
            })
        : ' Network Not  Found.');
    }
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" >  Network Details</h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
                <h4 className='my-3'> Network Deatils</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Coin Name</th>
                                <th scope="col">Network</th>
                            </tr>
                        </thead>
                        <tbody>
                            {network?.networks && network != undefined ? networkData() : ' '}
                        </tbody>
                    </table>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default Network