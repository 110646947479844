/** for trading bot reducers */
export const SAVE_FUND_TRANSACTIONS = '[USER] SAVE_FUND_TRANSACTIONS';
export const SAVE_CONVERTED_FUND_TRANSACTIONS = '[USER] SAVE_CONVERTED_FUND_TRANSACTIONS'
export const SAVE_ROI_TRANSACTIONS = '[USER] SAVE_ROI_TRANSACTIONS'
export const SAVE_REWARD_TRANSACTIONS = '[USER] SAVE_REWARD_TRANSACTIONS'
export const SAVE_USER_INFO = '[USER] SAVE_USER_INFO';
export const SAVE_PAID_USERS_DETAILS = '[USER] SAVE_PAID_USERS_DETAILS'
export const SAVE_TODAY_JOININGS = '[USER] SAVE_TODAY_JOININGS'
/** for trading bot reducers */

export const SAVE_USER_PROFILE = '[USER] SAVE_USER_PROFILE';
export const SAVE_BINANCE_BALANCE = '[USER] SAVE_BINANCE_BALANCE';
export const SAVE_USDT_TRANSACTIONS = '[USER] SAVE_USDT_TRANSACTIONS';
export const SAVE_ACTIVE_TRADES = '[USER] SAVE_ACTIVE_TRADES';
export const SAVE_CLOSED_TRADES = '[USER] SAVE_CLOSED_TRADES';
export const SAVE_WITHDRAW_REQUESTS = '[USER] SAVE_WITHDRAW_REQUESTS';
export const SAVE_PROFIT_HISTORY = '[USER] SAVE_PROFIT_HISTORY';
export const SAVE_BANNERS = '[USER] SAVE_BANNERS';
export const SAVE_WALLET_TRANSACTIONS = '[USER] SAVE_WALLET_TRANSACTIONS';
export const SAVE_INCOME_TRANSACTIONS = '[USER] SAVE_INCOME_TRANSACTIONS';
export const SAVE_INCOME_TYPE_TRANSACTIONS = '[USER] SAVE_INCOME_TYPE_TRANSACTIONS';
export const SAVE_PACKAGE = '[USER] SAVE_PACKAGE';
export const SAVE_OPEN_TICKET = '[USER] SAVE_OPEN_TICKET';
export const SAVE_CLOSE_TICKET = '[USER] SAVE_CLOSE_TICKET';
export const SAVE_ALL_USERS_DETAILS = '[USER] SAVE_ALL_USERS_DETAILS'
export const SAVE_NETWORK_DETAILS = '[USER] SAVE_NETWORK_DETAILS';
export const SAVE_DEPOSIT_DETAILS = '[USER] SAVE_DEPOSIT_DETAILS';
export const SAVE_DEPOSIT_TOKEN_DETAILS = '[USER] SAVE_DEPOSIT_TOKEN_DETAILS';
export const SAVE_ALL_DEPOSIT_TRX = '[USER] SAVE_ALL_DEPOSIT_TRX';
export const SAVE_P2P_ORDERS_TRANSACTIONS = '[USER] SAVE_P2P_ORDERS_TRANSACTIONS';
export const SAVE_P2P_POSTING_DATA = '[USER] SAVE_P2P_POSTING_DATA';
export const SAVE_VIEW_P2P_POSTING_DATA = '[USER] SAVE_VIEW_P2P_POSTING_DATA';
export const SAVE_SWAP_TOKEN_LIST = '[USER] SAVE_SWAP_TOKEN_LIST';
export const SAVE_SWAP_USER_TOKEN = '[USER] SAVE_SWAP_USER_TOKEN';
export const SAVE_SWAP_TOKEN_LIST_TRANSACTION  = '[USER] SAVE_SWAP_TOKEN_LIST_TRANSACTION';
export const SAVE_SWAP_SINGLE_TRANSACTION  = '[USER] SAVE_SWAP_SINGLE_TRANSACTION';
export const SAVE_NEWS = '[USER] SAVE_NEWS';
export const SAVE_P2P_ORDER = '[USER] SAVE_P2P_ORDER';
export const SAVE_TRADE_ORDER  = '[USER] SAVE_TRADE_ORDER';

/***Old code */
export const SAVE_USER_KYC_DETAIL = '[USER] SAVE_USER_KYC_DETAIL';
export const SAVE_USER_MORE_KYC_DETAIL = '[USER] SAVE_USER_MORE_KYC_DETAIL';
export const START_LOADER = '[LOADER] START LOADER';
export const STOP_LOADER = '[LOADER] STOP LOADER';
export const LOGOUT = '[AUTH] LOGOUT';

export const IS_FORGOT = "[USER] IS_FORGOT";
export const SAVE_LOGIN_USER_DETAIL = '[USER] SAVE_LOGIN_USER_DETAIL';
export const SAVE_SHOW_MODAL = '[MODAL] SAVE_SHOW_MODAL';
export const SAVE_SHOW_MODAL_CUSTOMIZE = '[MODAL] SAVE_SHOW_MODAL_CUSTOMIZE'

export const SAVE_MENUS = '[MENU] SAVE_MENUS';
export const SAVE_SINGLE_MENU = '[MENU], SAVE_SINGLE_MENU';
export const SAVE_SINGLE_MENU_FOR_SHOW = '[MENU[ SAVE_SINGLE_MENU_FOR_SHOW';

export const SAVE_DISHES = '[DISHES] SAVE_DISHES';
export const SAVE_DISH_DETAIL = '[DISHES] SAVE_DISH_DETAIL';

export const SAVE_TOPPINGS = '[TOPPING] SAVE_TOPPINGS';
export const SAVE_SUB_TOPPINGS = '[SUB TOPPING] SAVE_SUB_TOPPINGS';

export const SAVE_ORDERS = '[ORDERS] SAVE_ORDERS';
export const SAVE_SINGLE_ORDER = '[ORDERS] SAVE_SINGLE_ORDER';

export const SAVE_RESTAURANT_PROFILE = '[RESTAURANT] SAVE_RESTAURANT_PROFILE';
export const TOGGLE_MENU = '[TOGGLE_MENU] TOGGLE_MENU';

export const SAVE_FINANCE_LIST = '[FINANCE] SAVE_FINANCE_LIST';
export const SAVE_REVIEWS_LIST = '[REVIEW] SAVE_REVIEWS_LIST';
export const SAVE_NOTIFICATION = '[NOTIFICATIONS] SAVE_NOTIFICATION';
export const SAVE_WORKINGTIMES = '[WORKING TIMES] SAVE_WORKINGTIMES';