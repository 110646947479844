import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducer";
import { history } from "./history.reducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["persist"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const middlewares = [thunkMiddleware, routerMiddleware(history)];

  /* Redux devtools */
  const enhancers =
    process.env.NODE_ENV === "development" ?
      composeWithDevTools(applyMiddleware(...middlewares)) :
      applyMiddleware(...middlewares);

  /* Create Redux Store */
  const store = createStore(persistedReducer, enhancers);
  let persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
