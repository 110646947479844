import React from "react";
import { Form } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import { InputField } from "../../../components/Inputs";
import { required } from "redux-form-validators";
import {resentOtp}from "../../../redux/actions/user.action";

const ForgotPasswordForm = ({ handleSubmit }) => {
    
    const handleSubmotForm = () => {
        handleSubmit();
    }

    return (
        <>
            <Form onSubmit={handleSubmotForm}>
                <div className="veri--otp">
                    <div className="form-group d-flex">
                        {/* <span className="login__icon">
                            <HiDeviceMobile />
                        </span> */}
                        <Field
                            component={InputField}
                            type="text"
                            name="email"
                            className="form-control login--con"
                            placeholder=" Enter your email"
                            validate={[required(),]}
                        />

                    </div>

                    <div className="form-group mt-3">

                        <button type="submit" className="sbmit-btn mt-5 w-100 ">
                            Send OTP{" "}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default reduxForm({ form: "ForgotPasswordForm", enableReinitialize: true })(ForgotPasswordForm);
