import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { createBanner    } from '../../../redux/actions/dashboard.action';

const Banner = ({history}) => {
    const  dispatch = useDispatch();
    const [image,setimage] = useState();
    const [type,settype] = useState();
    const [error,seterror] = useState();
    const [title,settitle] = useState();


    const handleSubmitForm = (e) => { 
        e.preventDefault();
        if(image != undefined ){
            let formData = new FormData();
            formData.append('banner_image', image);
            formData.append('title', title); 
 
            dispatch(createBanner(formData,history));
        }else{
            seterror('Token Image Required.');
        }
      }
    const uploadImage = (e, input) => {
        let file = e.target.files[0];
        let createFileUrl = URL.createObjectURL(file);
        console.log('upload image file ',e)
        setimage(file)
        // setShowImage(URL.createObjectURL(e.target.files[0]))
    }
    console.log('type',type);
    return (
        <div>
            <div className="row pra">
                <div>
                    <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > Uplaod Banner</h4>
                </div>
            </div>
            <div className="table-area" > 
                <div className="card border-0 bg-transparent"> 
                <h4 className='my-3'> Uplaod Banner</h4>
                    <div className='table-responsive col-md-6'>
                        <form onSubmit={handleSubmitForm}>
                            <p className='text-danger'>{error != undefined ? error :' ' }</p>
                            <div className="form-group">
                                <label for="coinName">Title</label>
                                <input type="text" className="form-control"  placeholder="Title" onChange={e => settitle(e.target.value)}  required/>
                            </div>
                            <div className="form-group">
                                <label for="token_image">Banner Image</label>
                                <input type="file" className="form-control" onChange={uploadImage} accept=".jpg, .jpeg, .png" data-bv-field="documentFront"  placeholder="Token Image" />
                                <img src={image != undefined ? URL.createObjectURL(image):  ''} width='200' className='ml-5' />

                            </div>
                            
                        
                            {/* <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" for="exampleCheck1">Check me out</label>
                            </div> */}
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;