import React, { useState, useEffect } from 'react'
import logo from '../../_assets/images/logo.png';
import user_img from '../../_assets/images/user_image.png';
import { Link,NavLink, withRouter } from 'react-router-dom';

import { FaBars } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { FiUser, FiLogOut, FiBell } from "react-icons/fi";
import { getMenus } from '../../redux/actions/menu.action';
import { AiOutlineClose, AiOutlineFolderView } from "react-icons/ai";
import { RiDashboardFill,RiHome8Fill } from "react-icons/ri";
import { BiRestaurant, BiMap } from "react-icons/bi";
import { HiCurrencyDollar } from "react-icons/hi";
import { ImCodepen,ImHome } from "react-icons/im";
import { Radio } from 'semantic-ui-react';
import { FaUsers,FaTruck, FaUserAlt, FaFilePdf } from 'react-icons/fa'
import { connect, useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/actions/user.action';
import { toggleFn } from '../../redux/actions/menu.action'
import { getNotifications, updateOnlineStatusRestaurant } from '../../redux/actions/restaurant.action';
import '../../_assets/css/pro-2.css';

// import IndexBox from '../../pages/private/dashboard/indexBox';
// import Source from '../../pages/private/source/Source';


const Header = ({ history, props }) => {
    const [restaurantStatus, setRestaurantStatus] = useState(false);
    const userDetails = useSelector(state => state.persist.userDetails);
    const toggleData = useSelector(state => state.menu.toggleMenu);
    const [toggle, setToggle] = useState(false)
    const user = useSelector(state => state.dashboard.user);
    const noti = useSelector(state => state.restaurant.noti);

    const [profileMenu , SetProfileMenu] = useState(false);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logoutUser(history));
    }
    

    const toggleMenu = (value) => {
        setToggle(value)
    }
    const showProfileMenu = () => {
        console.log('profilemenu' , profileMenu)
        if(!profileMenu)
        SetProfileMenu(true)
        else
        SetProfileMenu(false)
    } 
    const openNav = () => {
        console.log('ok');
        document.getElementById('sidebar').className = 'sidebar s-left';
        console.log(window.location.href);
    }
    const closeNav = () => {
        console.log('ok');
        document.getElementById('sidebar').className = 'sidebar ';
    }

    const openNavnoti = () => {
        console.log('ok');
        const navDash = document.getElementById("belldata").className;
        console.log(navDash);
        const dropdown = 'bell-data';
        if(navDash === 'bell-data'){
        document.getElementById("belldata").style.display = "block";
         document.getElementById('belldata').className = 'bell-data drop';
        }
        else{
            document.getElementById("belldata").style.display = "none";

         document.getElementById('belldata').className = 'bell-data';
        }

    }
    const openNavmsg = () => {
        console.log('ok');
        const navDash = document.getElementById("megdata").className;
        console.log(navDash);
        const dropdown = 'bell-data';
        if(navDash === 'bell-data'){
        document.getElementById("megdata").style.display = "block";
         document.getElementById('megdata').className = 'bell-data drop';
        }
        else{
            document.getElementById("megdata").style.display = "none";

         document.getElementById('megdata').className = 'bell-data';
        }

    }
    const openNavlink = () => {
        console.log('ok');
        const navDash = document.getElementById("imgdata").className;
        console.log(navDash);
        const dropdown = 'img-data text-center bg-dark text-light';
        if(navDash === 'img-data text-center bg-dark text-light'){
        document.getElementById("imgdata").style.display = "block";
         document.getElementById('imgdata').className = 'img-data text-center bg-dark text-light drop';
        }
        else{
            document.getElementById("imgdata").style.display = "none";

         document.getElementById('imgdata').className = 'img-data text-center bg-dark text-light';
        }

    }
    const closeNavDashboard = () => {
        console.log('ok');
        document.getElementById('dropdown').className = 'dropdown ';
    }
    const openlink = window.location.href; 

    

    return (
        <>
            <div  className="header">
                <div className="logo-area">
                    <Link to="/">
                        <img className="img-fluit"  src={logo} width='78' height='78' />
                    </Link>
                </div>
                <div className="header-data">
                    <ul >
                        <li className='d-none' >
                            <a href={openlink} onClick={openNavnoti} className="nav-link" >  <i className='fas fa-bell'></i> <span id="noti">02 </span> </a>
                            <div className="bell-data" id="belldata">
                                <p className="p-3 bg-primary text-center" >  You Have <span>  2 Notifications </span> </p>
                                <div className="row m-0 p-3">
                                    <div className="col-2 b-img-area">
                                        <span>  <i className="fa fa-user-circle" aria-hidden="true"></i>    </span>
                                    </div>
                                    <div className="col-10 b-text-area">
                                        <p className=" just mb-0" > Just Now  </p>
                                        <p className="name"> <a href="/" >SADI ORLAF </a> </p>
                                        <p className="data-s">    Privacy settings changed </p>
                                    </div>
                                </div>
                                

                                <div className="row m-0 p-3">
                                    <div className="col-2 b-img-area">
                                        <span>  <i className="fa fa-user-circle" aria-hidden="true"></i>    </span>
                                    </div>
                                    <div className="col-10 b-text-area">
                                        <p className=" just mb-0" > Just Now  </p>
                                        <p className="name"> <a href="/" >SADI ORLAF </a> </p>
                                        <p className="data-s">    Privacy settings changed </p>
                                    </div>
                                </div>
                                <p className="view"> <a href="/"> View All</a>  </p>
                            </div>    
                        </li>
                        <li className='d-none'  >
                            <a href={openlink} className="nav-link" onClick={openNavmsg}> <i className="fa fa-envelope"></i> <span id="noti">02 </span> </a>
                            <div className="bell-data" id="megdata">
                                <p className="p-3 bg-primary text-center" >  You Have <span>  2 Notifications </span> </p>
                                <div className="row m-0 p-3">
                                    <div className="col-2 b-img-area">
                                        <span>  <i className="fa fa-user-circle" aria-hidden="true"></i>    </span>
                                    </div>
                                    <div className="col-10 b-text-area">
                                        <p className=" just mb-0" > Just Now  </p>
                                        <p className="name"> <a href="/" >SADI ORLAF </a> </p>
                                        <p className="data-s">    Privacy settings changed </p>
                                    </div>
                                </div>
                                <div className="row m-0 p-3">
                                    <div className="col-2 b-img-area">
                                        <span>  <i className="fa fa-user-circle" aria-hidden="true"></i>    </span>
                                    </div>
                                    <div className="col-10 b-text-area">
                                        <p className=" just mb-0" > Just Now  </p>
                                        <p className="name"> <a href="/" >SADI ORLAF </a> </p>
                                        <p className="data-s">    Privacy settings changed </p>
                                    </div>
                                </div>
                                <p className="view"> <a href="/"> View All</a>  </p>
                            </div>    
                        </li>
                        <li className='d-none' >
                            <a href={openlink} className="nav-link" >  <i className="fa fa-cog"></i>
                            </a>
                            <div className="bell-data" id="belldata">
                                <p className="p-3 bg-primary text-center" >  You Have <span>  2 Notifications </span> </p>
                                <div className="row m-0 p-3">
                                    <div className="col-2 b-img-area">
                                        <span>  <i className="fa fa-user-circle" aria-hidden="true"></i>    </span>
                                    </div>
                                    <div className="col-10 b-text-area">
                                        <p className=" just mb-0" > Just Now  </p>
                                        <p className="name"> <a href="/" >SADI ORLAF </a> </p>
                                        <p className="data-s">    Privacy settings changed </p>
                                    </div>
                                </div>
                                <div className="row m-0 p-3">
                                    <div className="col-2 b-img-area">
                                        <span>  <i className="fa fa-user-circle" aria-hidden="true"></i>    </span>
                                    </div>
                                    <div className="col-10 b-text-area">
                                        <p className=" just mb-0" > Just Now  </p>
                                        <p className="name"> <a href="/" >SADI ORLAF </a> </p>
                                        <p className="data-s">    Privacy settings changed </p>
                                    </div>
                                </div>
                                <p className="view"> <a href="/"> View All</a>  </p>
                            </div>    
                        </li>
                        <li className='d-none' >
                            <a href={openlink} className="nav-link" >  <i className='fa fa-users'></i>
                            </a>
                            <div className="bell-data" id="belldata">
                                <p className="p-3 bg-primary text-center" >  You Have <span>  2 Notifications </span> </p>
                                <div className="row m-0 p-3">
                                    <div className="col-2 b-img-area">
                                        <span>  <i className="fa fa-user-circle" aria-hidden="true"></i>    </span>
                                    </div>
                                    <div className="col-10 b-text-area">
                                        <p className=" just mb-0" > Just Now  </p>
                                        <p className="name"> <a href="/" >SADI ORLAF </a> </p>
                                        <p className="data-s">    Privacy settings changed </p>
                                    </div>
                                </div>
                                <div className="row m-0 p-3">
                                    <div className="col-2 b-img-area">
                                        <span>  <i className="fa fa-user-circle" aria-hidden="true"></i>    </span>
                                    </div>
                                    <div className="col-10 b-text-area">
                                        <p className=" just mb-0" > Just Now  </p>
                                        <p className="name"> <a href="/" >SADI ORLAF </a> </p>
                                        <p className="data-s">    Privacy settings changed </p>
                                    </div>
                                </div>
                                <p className="view"> <a href="/"> View All</a>  </p>
                            </div>    
                        </li>
                    </ul>
                    <form className='formNone d-none' action="">
                        <div className="input-group border-0">
                            <div className="input-group-prepend">
                              <button className="btn" type="button">  <i className='fas fa-search'></i>  </button>  
                            </div>
                            <input type="text" className="form-control form-width border-0" placeholder="Type and Hit Enter"/>
                          </div>
                    </form>
                </div>
                
                <div className="user-id"  id="userdropdown" >
                     <a href={openlink} onClick={openNavlink} ><img src={user_img} alt="use-pic" style={{width:'60px'}}/> <span id="dot"></span></a>
                    <div className="img-data text-center bg-dark text-light" id="imgdata" >
                        <p className="log-out"> <i className='fas fa-sign-out-alt'></i> <a href="/" onClick={logout}>  Log Out </a> </p>
                    </div>
                </div>
                <div className="bottom-line">
                    <i style={{backgroundColor: '#2c3e50'}}>  </i>
                    <i style={{backgroundColor: '#9857b2'}}>  </i>
                    <i style={{backgroundColor: '#2c81ba'}}>  </i>
                    <i style={{backgroundColor: '#f43367'}}>  </i>
                    <i style={{backgroundColor: '#5dc12e'}}>  </i>
                    <i style={{backgroundColor: '#feb506'}}>  </i>
                    <i style={{backgroundColor: '#e17c21'}}>  </i>
                    <i style={{backgroundColor: '#bc382a'}}>  </i>
                </div>
            </div>
        </>
    )
};

export default withRouter(Header);
