import * as types from '../actions/_types';

const initialState = {
    requests : {}, 
};

export default function withdraw(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_WITHDRAW_REQUESTS:
      return { ...state, requests: action.payload };   
    default:
      return state;
  }
}

