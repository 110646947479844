import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails,allUsers, getTradeOrders } from '../../../redux/actions/dashboard.action';
import { delete_news, get_news } from '../../../redux/actions/swap.action';
import { makeStyles } from '@material-ui/core';
import { Pagination, Icon } from 'semantic-ui-react';
const useStyles  = makeStyles(() => ({
    
    Pagination:{
        '& .MuiPaginationItem-root':{
            color:'#000',
            display:'flex',
        li:{
            backgroundColor:'#fff',
        } ,   
        }
    }
}));
const ViewOrders = () => {
    const [kycData,setkycData] = useState(); 
    const tradeOrder = useSelector(state => state.dashboard.tradeOrder);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTradeOrders({limit:5,skip:0,status:'ALL'}))
    }, [])

    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * 5);
        dispatch(getTradeOrders({ skip: pageNo, limit: 5 ,status:'ALL'})); 
        setpageNum(pageNo+1)
        // console.log('page',pageNo);
    }   

    const [page,setpage] = useState(1);
    const [pageNum,setpageNum] = useState(1);


var skipdata = page-1;

    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }

    const  delete_news_fun = (val) => {
        dispatch(delete_news({id:val}))
    }
    const usersData = () =>{
        return (
            tradeOrder?.orders &&  tradeOrder.orders.length > 0 ?
                tradeOrder.orders.map((row,i) => {
                    return(
                        <tr>
                            <td scope="row">{i+pageNum}</td>
                            <td>{row.tradingCurrency}</td>
                            <td>{row.orderType}</td>
                            <td>{row.mode}</td>
                            <td>{row.tokenPrice}</td>
                            <td>{row.amount}</td>
                            <td>{row.status}</td>
                            <td>{secondsToHms(row.createdAt)}</td>
                        </tr>
                    );
                })
        : ' Order Not  Found.');
    }
    console.log('tradeOrder',tradeOrder);

  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > All Order</h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'>All Order</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Pair</th>
                               <th scope="col">Types</th>
                                <th scope="col">Buy/Sell</th>
                                <th scope="col">Price</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Status</th>
                                <th scope="col">Time</th>

                            </tr>
                        </thead>
                        <tbody>
                            {usersData()}
                        </tbody>
                    </table>   
                    <div className='pagination'>
                        <Pagination className="mx-auto"
                            defaultActivePage={1}
                            onPageChange={pageChange}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={Math.ceil(tradeOrder?.transactionsCount / 5)}
                         />  
                    </div>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default ViewOrders