import React, { useEffect, useState } from 'react';
import  '../../../_assets/css/tables.css';
import {FundTransactions} from '../../../redux/actions/fund.action';
import { useDispatch, useSelector } from "react-redux"; 
import { toast } from '../../../components/toast';
import { currency } from "../../../_constants";
import { Pagination, Icon } from 'semantic-ui-react';
const Income_type = ({ match: { params: { type } } }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.dashboard.user);
    const fund_transactions = useSelector(state => state.fund.fund_transactions); 
    console.log('table page',user);
    // window.location.reload();
    let page_limit = 50
    useEffect(() => {
         dispatch(FundTransactions({limit:page_limit,skip:0})); 
    },[type]);

    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * page_limit);
        dispatch(FundTransactions({ skip: pageNo, limit: page_limit })); 
    }   
    const [page,setpage] = useState(1);
    var skipdata = page-1;
    return(
        <>
            <div className="card">
                <div className='card-header'> Fund Transaction</div>
                <div className='card-body'>
                    <table className="table table-responsive">
                        <thead className="text-dark">
                            <tr>
                            <th>#</th>
                            <th>User</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Description</th> 
                            <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                        {
                            fund_transactions.roi_list && fund_transactions.roi_list.length > 0 ?
                            fund_transactions.roi_list.map((row, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i+1}</td>   
                                                <td>

                                                    {row.user.length > 0 ? `${row.user[0].user_id}` : ''}
                                                    
                                                </td>
                                                <td className={row.amount > 0 ? `text-success` : `text-danger`}>{row.amount} {currency}</td>
                                                <td>{row.type}</td>
                                                <td>{row.description}</td>   
                                                <td>{row.createdAt}</td>   
                                            </tr>
                                        )
                                    })
                                : 'No Fund Transations Found.' 
                        } 
                        </tbody>
                    </table>
                    <div className='pagination'>
                        <Pagination className="mx-auto"
                            defaultActivePage={1}
                            onPageChange={pageChange}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={Math.ceil(fund_transactions.record_count / page_limit)}
                    />  
                    </div>  
                </div>
            </div>
        </>
    );
}

export default Income_type;