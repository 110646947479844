import React from "react";
import { Link } from 'react-router-dom'
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../../_assets/images/logo.png";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordFn } from '../../../redux/actions/user.action';
import { toast } from "../../../components/toast";
import { PROJECT_NAME } from "../../../_constants";

const ForgotPassword = ({ history }) => {
  const forgotPass = useSelector(state => state.persist.forgotPass);
  const dispatch = useDispatch();

  const handleForgotPass = (values) => {
    dispatch(forgotPasswordFn({ ...values},history));
  }

  // const handleVerifyOtp = (values) => {
  //   if (parseInt(values['otpCode']) === parseInt(forgotPass['otpCode'])) {
  //     dispatch(verifyOtpFn(history));
  //   } else {
  //     toast.info('Invalid OTP code.');
  //   }
  // }

  return (
    <>
      <div className="">
        <div className="bground wrapper login-page style-3" id="top">
          <div className="cp-container">
            <div className="col-md-12 card card-body p-0 ">
              <div className="row">
                <div className="col-md-6 bg-right text-center">
                  <div className="register-logo">
                      <img className="light" src={logo} style={{ maxWidth: '125px', borderRadius: '10px', marginBottom: '20px' }} />
                    <h5 className="page-title text-white">Welcome To {PROJECT_NAME}</h5>
                  </div>
                </div>
                <div className="col-md-6 mt-5">
                  <div className="">
                    <div className="cp-body p-3 pb-4">
                      <h5 className="head-title ">Forgot password</h5>
                      <div className="panel panel-primary mt-5">                         
                            <ForgotPasswordForm onSubmit={handleForgotPass} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
