import React, { useEffect, useState } from 'react'
// import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Pagination, Icon } from 'semantic-ui-react';
import { get_swap_Token, get_user_swap_Token } from '../../../redux/actions/swap.action';
import { Link } from 'react-router-dom';
const useStyles  = makeStyles(() => ({
    
    Pagination:{
        '& .MuiPaginationItem-root':{
            color:'#000',
            display:'flex',
        li:{
            backgroundColor:'#fff',
        } ,   
        }
    }
}));
const SwapTokens = () => {
    const dispatch = useDispatch();

    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * 5);
        dispatch(get_swap_Token({ skip: pageNo, limit: 5 })); 
    }   



    const [page,setpage] = useState(1);
var skipdata = page-1;
    useEffect(() =>{
        dispatch(get_swap_Token({limit:5,skip:0}))
        
    },[page]);

    const swap_list = useSelector(state => state.swap.swap_list);
    console.log('swap_list',swap_list)
    const classes = useStyles();
console.log('page',page);
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > Swap Tokens</h4>
                </div>
            </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> Swap Tokens Deatils</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Token Name</th>
                            <th scope="col">Contract Address</th>
                            <th scope='col'>Buy Price</th>
                            <th scope="col">Sell Price</th>
                            <th scope="col">Sell Status</th>
                            <th scope="col">Decimals</th>
                            <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                       { swap_list?.records?.map((val,key) => {
                            return(
                                    <tr>
                                        <th scope="row">{key+1}</th>
                                        <td>{val.tokenName}</td>
                                        <td>{val?.contractAddress}</td>
                                        <td>{val?.buyPrice}</td>
                                        <td>{val?.sellPrice}</td>
                                        <td>{val?.sellStatus}</td>
                                        <td>{val?.decimals}</td>
                                        <td><Link to={'/vendor/editSwapToken/'+val?._id} onClick={() => dispatch(get_user_swap_Token({id:val?._id}))} className='btn btn-sm table-btn bg-success' >View</Link> </td>

                                    </tr>
                            );
                       })}
                        
                            
                        </tbody>
                    </table>   
                    <div className='pagination'>
                    <Pagination className="mx-auto"
                        defaultActivePage={1}
                        onPageChange={pageChange}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={Math.ceil(swap_list?.total / 5)}
                />  
                    </div>         
          </div>
            </div>
        </div>
    </div>
  )
}

export default SwapTokens