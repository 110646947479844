import { fetch } from '../axios.service';
import { API_HOST } from "../../_constants";

/* AUTH APi's */
export const loginApi = (data, options) => {
  // console.log('login api data',data, options);
  return fetch("post", `${API_HOST}/admin/auth/signin`, data, options);
};


export const checkSponserApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/sponser_details`, data, options);
};
export const registerApi = (data, options) => { 
  console.log('register data 5',data,options)
  return fetch("post", `${API_HOST}/api/auth/signup`, data, options);
};

export const resentOtpApi = (data, options) => { 
  return fetch("post", `${API_HOST}/api/resent_verify_otp`, data, options);
};
// yadwinder
export const forgotApi = (data) => {
  return fetch("post", `${API_HOST}/api/resent_verify_otp`, data);
};
export const passwordResetApi = (data) => {
  return fetch("post", `${API_HOST}/api/auth/verify`, data);
};
export const incomeTransactionApi = (data, options) => {
  console.log(data, options);
  return fetch("post", `${API_HOST}/admin/income_transactions`, data, options); 
};

export const newTypeincomeTransactionApi = (data,options) => { 
  return fetch("post", `${API_HOST}/admin/income_type_transactions`, data, options); 
};
export const FundTransactionsApi = (data , options ) => {
  return fetch("post" , `${API_HOST}/admin/fund_transactions`, data, options);
}
export const convertedFundTransactionsApi = (data , options ) => {
  return fetch("post" , `${API_HOST}/admin/converted_fund_transactions`, data, options);
}
export const RoiTransactionsApi = (data , options ) => {
  return fetch("post" , `${API_HOST}/admin/roi/roi_list`, data, options);
}
export const RewardTransactionsApi = (data , options ) => {
  return fetch("post" , `${API_HOST}/admin/reward_list`, data, options);
}
export const send_fund_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/send_fund`, data, options);
};

export const fuelTransactionApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/income_transaction`, data, options);
};

// ////// 
export const createTicketApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/create_ticket`, data, options);
};
export const openTicketApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/open_tickets`, data, options);
};

export const closeTicketApi = (data, options) => {
  console.log('openTicketApi',data, options);
  return fetch("post", `${API_HOST}/api/closed_tickets`, data, options);
};
export const verifyEmailApi = (data, options) => { 
  return fetch("post", `${API_HOST}/api/auth/verify`, data, options);
};


export const getUserDetails = (data, options) => {
  return fetch("get", `${API_HOST}/admin/dashboard`, data, options);
};
export const getUserInfo = (data , options) => {
  return fetch("post", `${API_HOST}/admin/user_info`, data, options);
}
export const kycUsersDetail = (data, options) => {
  // console.log(data);
  return fetch("post", `${API_HOST}/admin/kyc_list`, data, options);
};
export const kycUsersMoreDetail = (data, options) => {
  // console.log(data);
  return fetch("post", `${API_HOST}/admin/user_kyc_document`, data, options);
};
export const allUsersApi = (data, options) => {
  // console.log(data);
  return fetch("post", `${API_HOST}/admin/users`, data, options);
};

export const todayJoiningsApi = (data, options) => {
  // console.log(data);
  return fetch("post", `${API_HOST}/admin/today_joinings`, data, options);
};

export const paidUsersApi = (data, options) => {
  // console.log(data);
  return fetch("post", `${API_HOST}/admin/paid_users`, data, options);
};
export const networkApi = (data, options) => {
  // console.log(data);
  return fetch("post", `${API_HOST}/admin/networks`, data, options);
};
export const depositApi = (data, options) => {
  // console.log(data);
  return fetch("post", `${API_HOST}/admin/deposit_tokens`, data, options);
};
export const kycAction = (data, options) => {
  return fetch("post", `${API_HOST}/admin/update_user_kyc_status`, data, options);
};
export const createTokenApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/add_deposit_token`, data, options);
};
export const deposit_token_details_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/deposit_token_details`, data, options);
};
export const update_contractAddress_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/add_contract_network`, data, options);
};
export const delete_user_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/delete_user`, data, options);
};
export const update_user_profile_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/update_user_profile`, data, options);
};
export const update_block_status_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/update_block_status`, data, options);
};
export const packageApi = (data, options) => {
  return fetch("get", `${API_HOST}/api/packages`, data, options);
};

export const Activation = (data, options) => {
  return fetch("post", `${API_HOST}/api/activate_account`, data, options);
};
export const scalingApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/check_roi_status`, data, options);
};
export const NewInvestment = (data, options) => {
  console.log('api data',data,options);
  return fetch("post", `${API_HOST}/api/start_investment`, data, options);
};
export const binanceBalance = (data, options) => {
  return fetch("get", `${API_HOST}/api/binance_balance`, data, options);
};

export const usdtTransactionsApi = (data, options) => {
  return fetch("get", `${API_HOST}/api/user/usdt_transactions`, data, options);
};
export const create_news_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/creatnews`,data, options);
};
export const news_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/viewnews`,data, options);
};
export const edit_news_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/updatenews`,data, options);
};
export const delete_news_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/removenews`,data, options);
};
export const active_trades_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/active_trades`, data, options);
};


export const active_closed_api = (data, options) => {
  return fetch("post", `${API_HOST}/api/closed_trades`, data, options);
};


//
export const otp_resent = (data, options) => {
  return fetch("post", `${API_HOST}/api/resent_verify_otp`, data, options);
};
 
export const withdrawRequestApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/withdrawapprove`, data, options);
};
export const allowWithdrawApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/allow_withdraw`, data, options);
}; 
export const profitHistoryApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/datewise_profit`, data, options);
};
//

 
export const withdrawRequestsListApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/withdrawrequest`, data, options);
};

export const tradeOrderApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/all_orders`, data, options);
};


export const bannersApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/viewbanner`, data, options);
};
export const createBannerApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/create_banner`, data, options);
};
export const deleteBannerApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/remove_banner`,data, options);
};
export const ActivateAccountApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/activate_account`, data, options);
};

export const walletTransferApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/wallet_transfer`, data, options);
};

export const walletTransactionApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/wallet_transaction`, data, options);
};


// 

export const updateWalletAddressApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/user/update_wallet_address`, data, options);
};

export const updateProfileApi = (data, options) => {
  return fetch("post", `${API_HOST}/api/user/update_profile`, data, options);
};
export const p2p_orders_transactions_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/p2porderstransactions`, data, options);
};
export const p2p_posting_data_api = (data,options) => {
  return fetch("post", `${API_HOST}/admin/p2p_postings`,data,options)
}
export const view_p2p_posting_data_api = (data,options) => {
  return fetch("post", `${API_HOST}/admin/p2p_posting_details`,data,options);
}
export const P2pOrders_api = (data,options) => {
  return fetch("post", `${API_HOST}/admin/p2p_orders`,data,options);
}
export const p2pOrderActonApi = (data, options) => {
  return fetch("post", `${API_HOST}/admin/update_p2p_order_status`, data, options);
};
export const swap_token_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/swaptokens`, data, options);
};
export const swap_user_token_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/getswaptoken`, data, options);
};
export const update_swap_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/updateswaptokens`, data, options);
};
export const swap_token_transaction_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/swap_transactions`, data, options);
};
export const swap_single_transaction_api = (data, options) => {
  return fetch("post", `${API_HOST}/admin/getswap_transactions`, data, options);
};







/*****Old Apis */

export const verifyOtpApi = (data) => {
  return fetch("post", `${API_HOST}/v1/restaurant/verifyOtp`, data);
};

// export const passwordResetApi = (data) => {
//   return fetch("post", `${API_HOST}/v1/restaurant/resetPassword`, data);
// };

/* RESTAURANT API's  */
export const onlineStatusRestaurantApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/editRestaurantOnlieStatus`, data, options);
};

export const restaurantProfileApi = (data, options) => {
  return fetch("get", `${API_HOST}/api/user`, data, options);
};

/* MENU APi's */
export const addMenuApi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addMenu`, data, options);
};

export const uploadCsvFileApi = (data, options) => {
  return fetch("post", `${API_HOST}/v1/restaurantMenu/importMenuUsingCSV`, data, options);
};


export const getMenuApi = (query, options) => {
  return fetch("get", `${API_HOST}/restaurant/menuList${query}`, {}, options);
};


export const deleteMenuApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteMenu${query}`, {}, options);
};

export const updateAvailibleStatusApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/editMenuOnlieStatus`, data, options);
};

export const getMenuDetailApi = (query, options) => {
  return fetch("get", `${API_HOST}/common/getRestaurantMenuDetail${query}`, {}, options);
};

/* DIsh API's */
export const getAllDispatch = (data, options) => {
  return fetch("post", `${API_HOST}/admin/transactions`,data, options);
};

export const addDishApi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addDishes`, data, options);
};

export const addDishImagesApi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addDisheImages`, data, options);
};

export const deleteDishApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteDishes${query}`, {}, options);
};

export const getDishDetailApi = (query, options) => {
  return fetch("get", `${API_HOST}/common/getRestaurantDisheDetail${query}`, {}, options);
};

export const dishOnlineStatusApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/editDishesOnlieStatus`, data, options);
};

/* ORDER API's */
export const getOrdersApi = (query, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getOrderList${query}`, {}, options);
};

export const orderAcceptedApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/orderAccepted`, data, options);
};

export const rejectOrderApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/orderRejected`, data, options);
};

export const orderDetailApi = (query, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getOrderDetail${query}`, {}, options);
};

/* CUSTOMIZATION API's */
export const addToppingTitleAPi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addToppingTitle`, data, options);
};

export const getToppingApi = (query, options) => {
  return fetch("get", `${API_HOST}/restaurant/getTopping${query}`, {}, options);
};

export const addToppingItemAPi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addToppinItem`, data, options);
};

export const updateToppingApi = (data, options) => {
  return fetch("put", `${API_HOST}/restaurant/setDefaultToppinItem`, data, options);
};

export const deleteToppingApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteTopping${query}`, {}, options);
};

export const deleteToppingTitleApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteToppingTitle${query}`, {}, options);
};

/* Sub Topping */
export const addSubToppingItemAPi = (data, options) => {
  return fetch("post", `${API_HOST}/restaurant/addSubToppinItem`, data, options);
};

export const getSubToppingApi = (query, options) => {
  return fetch("get", `${API_HOST}/common/getRestaurantSubToppings${query}`, {}, options);
};

export const deleteSubToppingTitleApi = (query, options) => {
  return fetch("delete", `${API_HOST}/restaurant/deleteSubTopping${query}`, {}, options);
};

/* Finance */ 
export const transactionApi = (query, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getTransactionData${query}`, {}, options);
};

/* Settings */
export const changePasswordApi = (data, options) => {
  return fetch("put", `${API_HOST}/v1/restaurant/changePassword`, data, options);
};

export const reviewRatingApi = (query, options) => {
  return fetch("get", `${API_HOST}/restaurant/getReviewList${query}`, {}, options);
};

/* Notification */
export const notificationApi = (query, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getNotificationList${query}`, {}, options);
};

/* Working Time */
export const updateWorkingHoursApi = (data, options) => {
  return fetch("post", `${API_HOST}/v1/restaurant/insertAndUpdateWorkingTime`, data, options);
};

export const updateWorkingStatusOfDayApi = (data, options) => {
  return fetch("put", `${API_HOST}/v1/restaurant/updateWorkingStatusOfDay`, data, options);
};

export const workingHoursApi = (data, options) => {
  return fetch("get", `${API_HOST}/v1/restaurant/getWorkingTime`, data, options);
};