import React, { useEffect, useState } from 'react';
import { RoiTransactions  } from '../../../redux/actions/roi.action';
import { useDispatch, useSelector } from "react-redux"; 
import { currency } from "../../../_constants";
import { Pagination, Icon } from 'semantic-ui-react';
const RoiList = () => {
    const dispatch = useDispatch();
    const limit = 100;
    const [skip,setSkip] = useState(0);
    const [page,setpage] = useState(1);
    const roi_transactions = useSelector(state => state.roi.roi_transactions); 
    useEffect(() => {
        dispatch(RoiTransactions({limit:limit,skip:0})); 
    },[]);
    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * limit);
        setSkip(pageNo)
        dispatch(RoiTransactions({ skip: pageNo, limit: limit })); 
        console.log('its roi_transactions ' , roi_transactions)
    }   
    

  return (
    <div> 
            <div className="table-area"> 
                <div className="card border-0 bg-transparent"> 
                <h4 className='my-3 text-dark'> 
                    Investment Deatils ({roi_transactions.total})
                    <a href="http://api.aiforexbot.us/admin/roi/download_roi_data" className='btn btn-lg bg-danger text-white pull-right ' target="_blank">Export</a>    
                </h4>
                
                    <div className='table-responsive'>
                        <table className="table table-hover text-white">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">User</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Return</th>
                                <th scope="col">Investment Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                roi_transactions?.records && roi_transactions?.records.length > 0 ?
                                roi_transactions?.records.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{skip + i  + 1}</td>   
                                                    <td>{`${row.user_id}`}</td>
                                                    <td className={row.amount > 0 ? `text-success` : `text-danger`}>{row.amount} {currency}</td>
                                                    <td>{row.return_rate} % monhtly <br/>{row.return_show} %daily</td> 
                                                    <td>{row.createdAt}</td>   
                                                </tr>
                                            )
                                        })
                                    : 'No Fund Transations Found.' 
                            } 
                            </tbody>
                        </table> 
                        <div className='pagination'>
                            <Pagination className="mx-auto"
                                defaultActivePage={1}
                                onPageChange={pageChange}
                                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                totalPages={Math.ceil(roi_transactions?.total / limit)}
                        />  
                        </div>     
                    </div>
                </div>
            </div>
    </div>
  )
}

export default RoiList