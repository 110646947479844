import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaRegUserCircle, FaUnlockAlt } from 'react-icons/fa'

import RegisterForm from "./RegisterForm";
import logo from "../../../_assets/images/logo.png";
import { register , checkSponser } from "../../../redux/actions/user.action";
import { withRouter } from "react-router";
import { PROJECT_NAME } from "../../../_constants";
// import "../../../_assets/css/login.css";
const Register = ({ history }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    values = { ...values, deviceType: 'WEB', deviceToken: 'WEB' };
    dispatch(register(values, history));
  }
  const validateSponser = (sponserId) => {
    console.log('this is outer sponser ' + sponserId);
    dispatch(checkSponser(sponserId , history))
  }
  return (
    <>
    <div className="bground wrapper login-page style-3" id="top">
      <div className="cp-container">
        <div className="col-md-12 card card-body p-0 ">
          <div className="row">
            <div className="col-md-6 bg-right text-center">
                <div className="register-logo"> 
                    <a href="/">
                      <img className="light" src={logo} style={{maxWidth : '125px',borderRadius:'10px',marginBottom: '20px'}}/>
                    </a>
                    <h5 className="page-title text-white">Welcome To {PROJECT_NAME}</h5>
                </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <div className="cp-body p-3 pb-4">
                    <h5 className="head-title">Sign Up To Your Account</h5>
                    <p className="text-center">Please Sign up to enter in a app </p>
                    <div className="panel panel-primary">
                      <RegisterForm onSubmit={handleSubmit} checkSponser={validateSponser}/>
                      
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Register;