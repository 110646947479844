import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaRegUserCircle, FaUnlockAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import LoginForm from "./LoginForm";
import logo from "../../../_assets/images/logo.png";
import bg from "../../../_assets/images/bgbanner.jpeg";
import { login } from "../../../redux/actions/user.action";
import { withRouter } from "react-router";
import { PROJECT_NAME } from "../../../_constants";
// import "../../../_assets/css/login.css";
// import "../../../_assets/css/form.css";

const Login = ({ history }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    values = { ...values, deviceType: 'WEB', deviceToken: 'WEB' };
    dispatch(login(values, history));
  }

  return (
    <>


<div className="container">
        <div className="row justify-content-center bd-ga">
            <div className="col-12 col-md-4">
                <div className="text-center">
                    <img src={logo} className="img-responsive bg-dark" style={{maxWidth : '125px',borderRadius:'10px',marginBottom: '20px'}}/>
                </div>
                <LoginForm onSubmit={handleSubmit} />     
            </div>
        </div>
    </div>
    {/* <div className="bground wrapper login-page style-3" style={{backgroundColor:'#DCDCDC'}} id="top">
      <div className="cp-container">
        <div className="col-md-12 card card-body p-0 ">
          <div className="row">
            <div className="col-md-6 bg-right text-center">
                <div className="register-logo"> 
                    <a href="/">
                      <img className="light" src={logo} style={{maxWidth : '125px',borderRadius:'10px',marginBottom: '20px'}}/>
                    </a>
                    <h5 className="page-title text-white">Welcome To {PROJECT_NAME}</h5>
                </div>
            </div>
            <div className="col-md-6">
              <div className="form-area">
                <div className="loginCard border-0">
                  <div className="f-header">
                  <h5 className="head-title">Login Now</h5>
                  </div>
                    <div className="">
                      <LoginForm onSubmit={handleSubmit} />
                      
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  */}
    </>
  );
};

export default withRouter(Login);