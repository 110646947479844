import React, { useEffect, useState } from 'react';
import  '../../../_assets/css/tables.css';
import { getUserDetails } from '../../../redux/actions/dashboard.action';
import { walletTransfer ,WalletTransactions} from '../../../redux/actions/wallet.action';
import {IncomeTypeTransactions} from '../../../redux/actions/income.action';
import { useDispatch, useSelector } from "react-redux"; 
import { toast } from '../../../components/toast';
import { currency } from "../../../_constants";
import { Pagination, Icon } from 'semantic-ui-react';

const Income_type = ({ match: { params: { type  , today_records} } }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.dashboard.user);
    const income_type_transactions = useSelector(state => state.income.income_type_transactions); 
    const limit = 10;
    const [skip,setSkip] = useState(0);
    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * limit); 
        setSkip(pageNo)
        dispatch(IncomeTypeTransactions({ skip: pageNo, limit: limit ,type : type ,today_records : (today_records == 0 ? false : true ) })); 
    }   
    
    useEffect(() => {
         dispatch(IncomeTypeTransactions({ skip: skip, limit: limit ,type : type  ,today_records : (today_records == 0 ? false : true )})); 
         
    },[type]);

    const newType = type.toUpperCase();
    

    return(
        <>
            <div className="card border-0 table-responsive">

            <h4> Income Transaction ( {newType.replace('_', ' ')}) ${income_type_transactions?.total_amount}</h4>
            <table className="table table-borderless">
                <thead className="text-light">
                    <tr>
                    <th>#</th>
                    <th>UserID</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Description</th> 
                    <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                
                {
                    income_type_transactions.transactions && income_type_transactions.transactions.length > 0 ?
                    income_type_transactions.transactions.map((row, i) => {
                                return (
                                    <tr>
                                        <td>{skip + i  + 1}</td>  
                                        <td>{row.user[0].user_id}</td>
                                        <td>{currency}{row.amount}</td>
                                        <td>{row.type}</td>
                                        <td>{row.description}</td>   
                                        <td>{row.createdAt}</td>   
                                    </tr>
                                )
                            })
                        : 'No Income Transations Found.' 
                } 
                </tbody>
            </table>
            <Pagination className="mx-auto"
                        defaultActivePage={1}
                        onPageChange={pageChange}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={Math.ceil(income_type_transactions.record_count / limit)}
                />    
            </div>
        </>
    );
}

export default Income_type;