import React from 'react'

const KYCform = () => {
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > KYC Form</h4>
            </div>
        </div>

        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> KYC From</h4>
                <div className='table-responsive col-md-6'>
                    <form>
                        <div className="form-group">
                            <label for="exampleInputEmail1">Bank Holder Name</label>
                            <input type="text" className="form-control"  placeholder="Bank Holder Name" />
                        </div>
                        <div className="form-group">
                            <label for="exampleInputEmail1">Bank Name</label>
                            <input type="text" className="form-control"  placeholder="Bank Name" />
                        </div>
                        <div className="form-group">
                            <label for="exampleInputEmail1">IFSE Code</label>
                            <input type="text" className="form-control"  placeholder="IFSE Code" />
                        </div>
                        <div className="form-group">
                            <label for="exampleInputEmail1">Account Number</label>
                            <input type="text" className="form-control"   placeholder="Account Number" />
                        </div>
                        <div className="form-group">
                            <label for="exampleInputEmail1">Branch Address</label>
                            <input type="text" className="form-control"  placeholder="Branch Address" />
                        </div>
                        
                       
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label" for="exampleCheck1">Check me out</label>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default KYCform;