import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader,AuthorizationHeaderImage } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 

const saveUserProfile = (data) => {
    return {
        type: types.SAVE_USER_PROFILE,
        payload: data
    }
}

const saveUserInfo = (data) => {
    return { 
        type : types.SAVE_USER_INFO,
        payload: data
    }
}
const PackageData1 = (data) => {
    // console.log('PackageData',data);
    return {
        type: types.SAVE_PACKAGE,
        payload: data
        
    }
    
}

const saveBinanceBalance = (data) => {
    return {
        type: types.SAVE_BINANCE_BALANCE,
        payload: data
    }
}

const saveUsdtTransactions = (data) => {
    return {
        type : types.SAVE_USDT_TRANSACTIONS,
        payload:data
    }
}


export const getUserDetails = () => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.getUserDetails({}, options).then((res) => { 
            res = res.data.data;
            dispatch(saveUserProfile(res));
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const getUserInfo = (data) => (dispatch , getState ) => {
    try{
        const { persist : { token } } = getState();
        const options = AuthorizationHeader(token);
        return apiService.getUserInfo(data,options).then((res) => {
            res = res.data.data;
            dispatch(saveUserInfo(res));
        }).catch((error) => {
            console.log('Failed 1', error);    
        })
    }catch(error){
        console.log('Failed', error);
    }
}

const saveUserkycDetail = (data) => {
    return {
        type: types.SAVE_USER_KYC_DETAIL,
        payload: data
    }
}
export const kycUsersDetails = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.kycUsersDetail(data, options).then((res) => { 
            res = res.data;
            dispatch(saveUserkycDetail(res));
            dispatch(stopLoader());
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
const saveUserkycMoreDetail = (data) => {
    return {
        type: types.SAVE_USER_MORE_KYC_DETAIL,
        payload: data
    }
}
export const kycUserMoreDetail = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.kycUsersMoreDetail(data, options).then((res) => { 
            // console.log(res.data);
            res = res.data;
            dispatch(saveUserkycMoreDetail(res));
            dispatch(stopLoader());

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}

export const kycAction = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
// console.log('data',data);
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.kycAction(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success('Kyc successfully '+ data.kyc_status);
                history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
const saveAllUsers = (data) => {
    return {
        type: types.SAVE_ALL_USERS_DETAILS,
        payload: data
    }
}
const savePaidUsers = (data) => {
    return {
        type : types.SAVE_PAID_USERS_DETAILS,
        payload: data,
    }
}
const saveTodayJoinings = (data) => {
    return {
        type : types.SAVE_TODAY_JOININGS,
        payload: data,
    }
}
export const todayJoinings = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.todayJoiningsApi(data, options).then((res) => { 
            res = res.data;
            dispatch(saveTodayJoinings(res));
            dispatch(stopLoader());

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}
export const allUsers = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.allUsersApi(data, options).then((res) => { 
            res = res.data;
            dispatch(saveAllUsers(res));
            dispatch(stopLoader());

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}

export const paidUsers = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.paidUsersApi(data, options).then((res) => { 
            res = res.data;
            dispatch(savePaidUsers(res));
            dispatch(stopLoader());

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}

const saveNetworkDetail = (data) => {
    return {
        type: types.SAVE_NETWORK_DETAILS,
        payload: data
    }
}
export const networkDetail = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.networkApi(data, options).then((res) => { 
            res = res.data;
            dispatch(saveNetworkDetail(res));
            dispatch(stopLoader());

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}

const saveDepositDetail = (data) => {
    return {
        type: types.SAVE_DEPOSIT_DETAILS,
        payload: data
    }
}
export const depositDetail = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.depositApi(data, options).then((res) => { 
            res = res.data;
            dispatch(saveDepositDetail(res));
            dispatch(stopLoader());

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}

const save_deposit_token_details = (data) => {
    return {
        type: types.SAVE_DEPOSIT_TOKEN_DETAILS,
        payload: data
    }
}
export const deposit_token_details = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.deposit_token_details_api(data, options).then((res) => { 
            res = res.data;
            dispatch(save_deposit_token_details(res));
            dispatch(stopLoader());

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}
//
export const delete_user = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());     
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.delete_user_api(data, options).then((res) => { 
            if (res.data.success == 1) { 
                toast.success('Email updated successfully'); 
                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}
export const update_user_email = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());     
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.update_user_profile_api(data, options).then((res) => { 
            if (res.data.success == 1) { 
                toast.success('User Deleted successfully'); 
                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}
export const update_block_status = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());     
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.update_block_status_api(data, options).then((res) => { 
            if (res.data.success == 1) { 
                toast.success('Block Status updated success'); 
                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}
export const update_contractAddress = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        // console.log('function',data)
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.update_contractAddress_api(data, options).then((res) => { 
            if (res.data.success == 1) {
                
                toast.success('Contract Added successfully ');
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}

export const createToken = (data, history,setscreenLoader) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeaderImage(token)
        return apiService.createTokenApi(data, options).then((res) => {

            console.log('outksdfbkjsd ', res)
            if (res.data.success == 1) {
                toast.success('Token Created successfully.');
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
                setscreenLoader('false');

            }
        }).catch((error) => {
            console.log('Failed', error);
            setscreenLoader('false');

            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
// -----------------------------------------------------------------------


export const activativation = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.Activation(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
                window.location.reload();
            }else{
                toast.error(res.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const createTicket = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.createTicketApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const Newinvestment = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.NewInvestment(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const activateAccount = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.ActivateAccountApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success('Account Activated Successfully'); 
                dispatch(getUserDetails())
            }else{
                toast.error(res.data.status); 
                dispatch(getUserDetails())
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const OtpResent = (email) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.otp_resent({email : email}, options).then((res) => { 
            console.log(res.data)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
 

export const BinanceBalance = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.binanceBalance({}, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                dispatch(saveBinanceBalance(res.data.balance));
                // toast.success(res.data.balance);
            }else{
                dispatch(saveBinanceBalance(res.data.message));
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}


export const SubmitWithdrawRequest = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.withdrawRequestApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.status);
            }else{
                toast.error(res.data.status);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const updateWalletAddress = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.updateWalletAddressApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const updateProfile = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.updateProfileApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const getUsdtTransactions = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.usdtTransactionsApi({}, options).then((res) => { 
            console.log('usdt response' , res.data) 
            if(res.data.success == 1){
                dispatch(saveUsdtTransactions(res.data));
                // toast.success(res.data.balance);
            }else{
                // dispatch(saveBinanceBalance(res.data.message));
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const getPackage = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.packageApi({}, options).then((res) => { 
            console.log('usdt response' , res.data) 
            if(res.data.success == 1){
                dispatch(PackageData1(res.data.pacakges));
                // toast.success(res.data.balance);
            }else{
                // dispatch(saveBinanceBalance(res.data.message));
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
const SaveBanners = (data) => {
    return {
        type : types.SAVE_BANNERS,
        payload:data
    }
}

export const getBanners = (data) => (dispatch, getState) =>{
    try {   
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);
        return apiService.bannersApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                dispatch(SaveBanners(res.data.record));
                // toast.success(res.data.balance);
            }else{ 
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const createBanner = (data, history,setscreenLoader) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeaderImage(token)
        return apiService.createBannerApi(data, options).then((res) => {

            console.log('outksdfbkjsd ', res)
            if (res.data.success == 1) {
                toast.success('Banner Added successfully.');
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
                setscreenLoader('false');

            }
        }).catch((error) => {
            console.log('Failed', error);
            setscreenLoader('false');

            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const deleteBannerAction = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());

        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token)
        return apiService.deleteBannerApi(data, options).then((res) => {

            console.log('outksdfbkjsd ', res)
            if (res.data.success == 1) {
                toast.success(res.data.message);
                // history.push('/vendor/uploadproof');
                dispatch(getBanners())
                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
                // setscreenLoader('false');

            }
        }).catch((error) => {
            console.log('Failed', error);
            // setscreenLoader('false');

            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}




const SaveTradeOrders = (data) => {
    return {
        type : types.SAVE_TRADE_ORDER,
        payload:data
    }
}

export const getTradeOrders = (data) => (dispatch, getState) =>{
    try {   
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);
        return apiService.tradeOrderApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                dispatch(SaveTradeOrders(res.data));
                // toast.success(res.data.balance);
            }else{ 
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}