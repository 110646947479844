import * as types from '../actions/_types';

const initialState = {
  loading: false
};

export default function loader(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.START_LOADER:
      return { ...state, loading: true };
    
    case types.STOP_LOADER:
      return { ...state, loading: false }
    
    default:
      return state;
  }
}

