import React, { useState } from "react";
import { Form } from 'semantic-ui-react';
import { reduxForm, Field } from "redux-form";
import { InputField } from '../../../components/Inputs';
import { BiKey } from 'react-icons/bi'
import { required } from 'redux-form-validators';
import { AiFillEye } from "react-icons/ai";
import { Link } from 'react-router-dom'

const ResetPasswordForm = ({ handleSubmit }) => {
    const [ passwordType, setPasswordType ] = useState("password");
    const handleSubmitForm = () => {
        handleSubmit();
    }

    const passwordTypeFn = () => {
        passwordType === "password"? setPasswordType("text"):setPasswordType("password"); 
    
    }
    return (
        <>
            <Form onSubmit={handleSubmitForm} autoComplete="off">
                <div className="form-group sysPass d-flex">
                    <Field component={InputField} name="email" className="form-control login--con" placeholder=" ENTER YOUR email" validate={[required()]} />
                </div>
                <div className="form-group sysPass d-flex">
                    <Field component={InputField}  name="otp" className="form-control login--con" placeholder="enter your otp" validate={[required()]} />
                </div>
                
                <div className="form-group sysPass d-flex">
                    {/* <span className="login__icon"><BiKey/></span> */}
                    <Field component={InputField} name="password" type={passwordType} className="form-control login--con" placeholder="Enter YOUR NEW PASSWORD" validate={[required()]} />
                    <div className="pass__icon" onClick={passwordTypeFn}> <AiFillEye /> </div>
                </div>
                <button className="sbmit-btn">SUBMIT </button>
                {/* <div className="form-group">
                    <a href="/" className="m-l-5" style={{ float: "right", color: "#000", padding: "10px 0", }}> Forgot Password? </a>
                </div> */}
            </Form>
            {/* <span className="for__pass"><a href="/" className="mt-3 text-muted"> Forgot Password?</a></span> */}
            <div className="form-group text-center mb-4 mt-5">
                        <Link to="/" className="btn btn-primary d-inline w-100 rounded border-0">Back to Home</Link>
                    </div>
        </>
    );
};

const validate = (formValues) => {
    let errors = {};
    if (formValues.confirm_password !== formValues.newPassword) {
        errors.confirm_password = 'Password do not matched';
    }
    return errors;
}

export default reduxForm({ form: "ResetPasswordForm", validate, enableReinitialize: true })(ResetPasswordForm);