import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createToken, deposit_token_details } from '../../../redux/actions/dashboard.action';

const EditToken = (props) => {
    const  dispatch = useDispatch();
    const deposit = useSelector(state => state.kycData.deposit_token_detail?.tokenDetails?.[0]);

    const [image,setimage] = useState();
    const [data,setdata] = useState(deposit?.tokenDetails?.[0]);


    const dataId  = props.match.params.id;

    useEffect(() => {
        dispatch(deposit_token_details({tokenID:dataId}))
    }, [])
    
// console.log('deposit',deposit[0]);
console.log('data',dataId)
    const handleSubmitForm = (e) => { 
        // e.preventDefault();
        // if(image != undefined ){
        //     let formData = new FormData();
        //     formData.append('token_image', image); 
        //     formData.append('coinName', coin); 
        //     formData.append('type', type); 
        //     formData.append('decimals', decimals); 
        //     formData.append('confirmations', confirmations); 

        //     dispatch(createToken(formData,history));
        // }else{
        //     seterror('Token Image Required.');
        // }
      }

    const uploadImage = (e, input) => {
        let file = e.target.files[0];
        let createFileUrl = URL.createObjectURL(file);
        // console.log('upload image file ',e)
        setimage(file)
        // setShowImage(URL.createObjectURL(e.target.files[0]))
    }
    
    return (
        <div>
            <div className="row pra">
                <div>
                    <i className="fa fa-cube" ></i>
                </div>
                <div>
                    <div className="pra-text" >
                        <h4 className="mb-0" > Edit Token</h4>
                    </div>
                </div>
            </div>
            <div className="table-area" > 
                <div className="card border-0 bg-transparent">
                    <div className='d-flex justify-content-between'> 
                        <div><h4 className='my-3'> Edit Token</h4></div>  
                        <div><Link to={'/vendor/UpdateAddress/'+dataId} className='btn table-btn bg-success'>Create Contract </Link></div>
                    </div> 
                    
                    <div className='row'>
                        <div className='table-responsive col-md-6'>
                            <form onSubmit={handleSubmitForm}>
                                <div className="form-group">
                                    <label for="coinName">Coin Name</label>
                                    <input type="text" className="form-control"  placeholder="Coin Name"  value={deposit?.coinName} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Type</label>
                                    <select className="form-select" id="mySelect"   >
                                        {/* <option disabled>Select Type</option> */}
                                        <option >{deposit?.type}</option>
                                    </select>                      
                                </div>
                                <div className="form-group">
                                    <label for="decimals">Decimals</label>
                                    <input type="number" className="form-control"  placeholder="Decimals" value={deposit?.decimals} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Confirmations</label>
                                    <input type="number" className="form-control"   placeholder="Confirmations"  value={deposit?.confirmations} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label for="token_image">Token Image</label>
                                    {/* <input type="file" className="form-control"  accept=".jpg, .jpeg, .png" data-bv-field="documentFront"  placeholder="Token Image" /> */}
                                    <img src={deposit?.image} width='100' className='ml-5' />

                                </div>
                                
                            
                            </form>   
                        </div>
                        <div className='table-responsive col-md-6'>
                            <table className="table table-hover text-white">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Coin Name</th>
                                        <th scope="col">Contract Address</th>
                                        {/* <th scope="col">Image</th> */}
                                        <th scope="col">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {deposit?.networks.map((row,i)=>{
                                        return(
                                            <tr>
                                                <td>{i+1}</td>
                                                <td>{deposit?.networkDetails[i]?.Network}</td>
                                                <td>{row.contractAddress}</td>
                                                <td><button className='btn table-btn bg-warning'>REMOVE</button></td>

                                            </tr>
                                        );
                                    })}
                                    
                                </tbody>
                            </table>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditToken;