import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 



const saveFundTransactions = (data) => {
    return {
        type : types.SAVE_FUND_TRANSACTIONS,
        payload:data
    }
}

const saveConvertedFundTransactions = (data) => {
    return {
        type : types.SAVE_CONVERTED_FUND_TRANSACTIONS,
        payload:data
    }
}



export const FundTransactions = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.FundTransactionsApi(data,options).then((res) => { 
            if(res.data.status == 1){
                dispatch(saveFundTransactions(res.data));
                toast.success(res.data.message);
                dispatch(stopLoader());
            }else{
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
} 


export const ConvertedFundTransactions = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.convertedFundTransactionsApi(data,options).then((res) => { 
            if(res.data.status == 1){
                dispatch(saveConvertedFundTransactions(res.data));
                toast.success(res.data.message);
                dispatch(stopLoader());
            }else{
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
} 
export const sendFund = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        // console.log('function',data)
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.send_fund_api(data, options).then((res) => { 
            console.log(res)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}
