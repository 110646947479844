import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { p2p_orders_transactions } from '../../../redux/actions/wallet.action';

const P2pTransaction = () => {
    const dispatch = useDispatch();
    const [data ,setData] = useState();
    const p2p_trans = useSelector(state => state.wallet.p2p_trans);
    console.log('p2p_trans',p2p_trans);
    useEffect(() => {
        dispatch(p2p_orders_transactions());
    },[]);


    const  showData = () => {
        return p2p_trans?.transactions?.map((val,key) => {
            return(
                <tr>
                    <th scope="row">{key+1}</th>
                    <td>{val.min_limit}</td>
                    <td>{val.price}</td>
                    <td>{val.quantity}</td>
                    <td>{val.sell_currency}</td>
                </tr>
            );
        })
        
    }
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > P2P Transaction</h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> P2P Deatils</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Min Limit</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope='col'>Sell Currency</th>
                            </tr>
                        </thead>
                        <tbody>
                          {showData()}
                            
                        </tbody>
                    </table>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default P2pTransaction
