import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 


const saveIncomeTransactions = (data) => {
    return {
        type : types.SAVE_INCOME_TRANSACTIONS,
        payload:data
    }
}


export const IncomeTransactions = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.incomeTransactionApi(data, options).then((res) => { 
            console.log('new api data ',res);
            if(res.data.status == 1){
               dispatch( saveIncomeTransactions(res.data));
                toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                console.log('error is shere ',res.data )
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}




const saveIncomeTypeTransactions = (data) => {
    return {
        type : types.SAVE_INCOME_TYPE_TRANSACTIONS,
        payload:data
    }
}


export const IncomeTypeTransactions = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        // const type = 'investment_return';
        return apiService.newTypeincomeTransactionApi(data,options).then((res) => { 
            console.log('income_type_transaction ',res);
            if(res.data.status == 1){
                dispatch(saveIncomeTypeTransactions(res.data));
                toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const saveOpenTicket = (data) => {
    return {
        type : types.SAVE_OPEN_TICKET,
        payload:data
    }
}


export const OpenTicketData = () => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.openTicketApi({}, options).then((res) => { 
            console.log('new api data ',res.data.tickets);
            if(res.data.success == 1){
               dispatch(saveOpenTicket(res.data.tickets));
                toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const saveCloseTicket = (data) => {
    return {
        type : types.SAVE_CLOSE_TICKET,
        payload:data
    }
}


export const CloseTicketData = () => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.closeTicketApi({}, options).then((res) => { 
            console.log('new api data ',res.data.tickets);
            if(res.data.success == 1){
               dispatch(saveCloseTicket(res.data.tickets));
                toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
