import React, { useEffect, useState } from 'react' 
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo ,update_block_status ,update_user_email , delete_user} from '../../../redux/actions/dashboard.action'; 
const User_details = ({ match: { params: { id } } }) => {

    const user_info = useSelector(state => state.dashboard.user_info);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserInfo({user_id : id}))
        // console.log('usder iunf ' , user_info.user?.email)
    }, [])
    const [newEmail , setnewEmail] = useState(user_info?.user?.email);
    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }
    const directsData = () =>{
        return (
            user_info?.directs &&  user_info?.directs.length > 0 ?
                user_info?.directs.map((row,i) => {
                    return(
                        <tr>
                            <th scope="row">{i+1}</th> 
                            <td>{row.email}<br/>{row.user_id}<br/>{row.name}</td>
                            <td>
                                Package : ${row.package_amount > 0 ? row.package_amount : 0}<br/>
                                Team Business : ${row.team_business > 0 ? row.team_business : 0}<br/>
                                Verified : {row.isVerified ? 'Yes' : 'No'}
                            </td>
                            <td>{secondsToHms(row.createdAt)}</td>
                        </tr>
                    );
                })
        : ' Users Not  Found.');
    }
    const usdtDeposits = () =>{
        return (
            user_info?.usdt_deposit &&  user_info?.usdt_deposit.length > 0 ?
                user_info?.usdt_deposit.map((row,i) => {
                    return(
                        <tr>
                            <th scope="row">{i+1}</th> 
                            <td>
                                from : {row.from} <br/>
                                to : {row.to} <br/>
                                hash : {row.hash}
                            </td>
                            <td>
                                Value : ${row.value}<br/> 
                            </td>
                            <td>{secondsToHms(row.createdAt)}</td>
                        </tr>
                    );
                })
        : ' Deposits Not  Found.');
    }
    const teamData = () => {
        return (
            user_info?.team && user_info?.team.length > 0 ? 
                user_info?.team.map((row , i) => {
                    return(<tr>
                        <th scope="row">{i+1}</th> 
                        <td>{row.downline_id}</td>
                        <td>{row.level}</td>
                        <td>${row.package_amount}</td>
                        <td>{secondsToHms(row.createdAt)}</td>
                    </tr>)
                })
            : 'Team not found'
        )
    }
    const IncomeHistory = () => {
        return (
            user_info?.income_transactions && user_info?.income_transactions.length > 0 ? 
                user_info?.income_transactions.map((row , i) => {
                    return(<tr>
                        <th scope="row">{i+1}</th> 
                        <td>${row.amount}</td>
                        <td>{row.description}</td> 
                        <td>{secondsToHms(row.createdAt)}</td>
                    </tr>)
                })
            : 'income not found'
        )
    }
    const investmentData = () => {
        return (
            user_info?.investments && user_info?.investments.length > 0 ? 
                user_info?.investments.map((row , i) => {
                    return(<tr>
                        <th scope="row">{i+1}</th> 
                        <td>{row.amount}</td>
                        <td>{row.days}</td>
                        <td>{row.return_rate}</td>
                        <td>{secondsToHms(row.createdAt)}</td>
                    </tr>)
                })
            : 'Team not found'
        )
    }
    let updateBlockStatus = (user) => {
        let text = "Are You Sure to "+(user_info?.user?.block_status == true ? 'Unblock ' : 'Block')+" this User";
        if (window.confirm(text) == true) { 
            dispatch(update_block_status({user_id : user_info?.user?._id}))
            window.location.reload();
        } 
    }
    let DeleteAccount = (user) => {
        let text = "Are You Sure to Delete this User";
        if (window.confirm(text) == true) { 
            dispatch(delete_user({user_id : user_info?.user?._id}))
            window.location.reload();
        } 
    }
    let updateEmail = () => {
        let text = "Are You Sure to change email for this User";
        if (window.confirm(text) == true) { 
            dispatch(update_user_email({user_id : user_info?.user?._id , email : newEmail}))
            window.location.reload();
        } 
    }
  return (
    <div> 
        <div> 
            <div className="table-area"> 
                <div className="card border-0 bg-transparent">  
                    <div className='row'> 
                        <div className="col-lg-6">
                            <div className='card  bg-light mt-2 pt-5'>
                                Email : {user_info?.user?.email} <br/>
                                Name : {user_info?.user?.name} <br/>
                                Sponser : {user_info?.user?.sponser_id} <br/>
                                Package Amount : ${user_info?.user?.package_amount > 0 ? user_info?.user?.package_amount : 0} <br/>
                                Joining Date : {user_info?.user?.createdAt} <br/>
                                Team : {user_info?.user?.team} <br/>
                                Team Business : ${user_info?.user?.team_business} <br/>
                                {user_info?.user?.delete_status ? 'This Account deleted' : ''}
                                <button type="button" className='btn btn-danger' onClick={DeleteAccount}>{user_info?.user?.delete_status == true ? '' : 'Delete Account'}  </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className='card  bg-light mt-2 pt-5'>
                                Main Wallet : ${user_info?.main_wallet} <br/>
                                Master Wallet : ${user_info?.master_wallet} <br/>
                                Income Wallet : ${user_info?.user?.income} <br/>
                                Fund Wallet : ${user_info?.user?.wallet_amount} <br/>
                                Wallet : {user_info?.wallet_addrees?.user_address} <br/>
                                Block Status : {user_info?.user?.block_status ? 'Blocked' : 'Unblocked'} <br/> 
                                <button type="button" className='btn btn-success' onClick={updateBlockStatus}>{user_info?.user?.block_status ? 'Unblock' : 'Block'} User</button>
                                
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className='card  bg-light mt-2 pt-5'>
                                <div className='form-group'>
                                    <label>Email</label>
                                    <input type="email" name="email" value={newEmail} onChange={e => setnewEmail(e.target.value)} className='form-control'/>
                                </div>
                                <div className='form-group d-none'>
                                    <label>Password</label>
                                    <input type="text" className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <button type="button" className='btn btn-success' onClick={updateEmail}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h4 className='my-3 text-dark text-center'> Directs Team</h4>
                            <div className='table-responsive'  style={{minHeight : '300px' , overflow : 'scroll' , maxHeight : '300px'}}>
                                <table className="table table-hover text-white">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th> 
                                            <th scope="col">UserId</th>
                                            <th scope="col">Package</th>
                                            <th scope="col">Joined At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {directsData()}
                                    </tbody>
                                </table>  
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h4 className='my-3 text-dark text-center'>Income Records</h4>
                            <div className='table-responsive'  style={{minHeight : '300px' , overflow : 'scroll' , maxHeight : '300px'}}>
                                <table className="table table-hover text-white">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th> 
                                            <th scope="col">Amount</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {IncomeHistory()}
                                    </tbody>
                                </table>    
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <h4 className='my-3 text-dark text-center'> All Team</h4>
                            <div className='table-responsive' style={{overflow : 'scroll',minHeight : '300px'  , maxHeight : '300px'}}>
                                <table className="table table-hover text-white">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th> 
                                            <th scope="col">UserId</th>
                                            <th scope="col">Level</th>
                                            <th scope="col">Package Amount</th>
                                            <th scope="col">Joined At</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {teamData()}
                                    </tbody>
                                </table>   
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h4 className='my-3 text-dark text-center'> Investments</h4>
                            <div className='table-responsive' style={{minHeight : '300px' , overflow : 'scroll' , maxHeight : '300px'}}>
                                <table className="table table-hover text-white">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th> 
                                            <th scope="col">Amount</th>
                                            <th scope="col">Days</th>
                                            <th scope="col">Return Rate</th>
                                            <th scope="col">Investment At</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {investmentData()}
                                    </tbody>
                                </table>   
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <h4 className='my-3 text-dark text-center'> Usdt Deposits</h4>
                            <div className='table-responsive'  style={{minHeight : '300px' , overflow : 'scroll' , maxHeight : '300px'}}>
                                <table className="table table-hover text-white">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th> 
                                            <th scope="col">From</th>
                                            <th scope="col">Amount</th> 
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {usdtDeposits()}
                                    </tbody>
                                </table>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default User_details