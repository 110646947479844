import React, { useState , useEffect } from "react";
import { Form } from 'semantic-ui-react';
import { useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { InputField } from '../../../components/Inputs';
import { HiMail} from 'react-icons/hi'
import { BiKey } from 'react-icons/bi'
import { required } from 'redux-form-validators';
import { AiFillEye } from "react-icons/ai";
import { Link } from 'react-router-dom'
import { resentOtp } from "../../../redux/actions/user.action";

const VerifyAccountForm = ({ handleSubmit , email }) => {
    const dispatch = useDispatch();
    const [ userEmail, setuserEmail ] = useState(email);
    const handleSubmitForm = (values) => {
        console.log('oo ' ,values)
        handleSubmit();
    }
    const resendOtp = () => {
        dispatch(resentOtp(userEmail));
    } 


    return (
        <>

            <Form onSubmit={handleSubmitForm}>
                <div className="panel-body">
                    <div className="details password-form">
                        <div className="form-group">
                            <div className="label-area">
                                <label>Email:</label>
                            </div>
                            <div className="row-holder">
                                <div className="">
                                    <input type="email" type="email" name="email" value={userEmail} onChange={e => setuserEmail(e.target.value)} className="form-control" placeholder="Email" required/>
                                    Click <span type="button" onClick={resendOtp}> Here </span> Resend OTP
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="label-area">
                                <label>OTP:</label>
                            </div>
                            <div className="row-holder">
                            <div className="pass-eye-fields">
                                <Field component={InputField} type="number" name="otp" className="form-control" placeholder="OTP" validate={[required()]} />
                            </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <button className="sbmit-btn"> Verify </button>
                        </div>
                        {/* <div className="form-group">
                            <p className="text-center">
                                <a className="forgot-link" href="">Forgot password?</a> or 
                                <Link to="register" className="forgot-link">Sign Up</Link>
                            </p>
                        </div> */}
                        <div className="form-group text-center mb-4">
                            <Link to="/" className="btn btn-primary d-inline w-100 rounded border-0">Back to Home</Link>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default reduxForm({ form: 'VerifyAccountForm', enableReinitialize: true })(VerifyAccountForm);