import React, { useEffect, useState } from 'react';
import { RewardTransactions  } from '../../../redux/actions/roi.action';
import { useDispatch, useSelector } from "react-redux"; 
import { currency } from "../../../_constants";
import { Pagination, Icon } from 'semantic-ui-react';
const RewardList = () => {
    const dispatch = useDispatch();
    const limit = 10;
    const [skip,setSkip] = useState(0);
    const reward_transactions = useSelector(state => state.roi.reward_transactions); 
    useEffect(() => {
        dispatch(RewardTransactions({limit:limit,skip:0})); 
    },[]);
    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * limit);
        setSkip(pageNo)
        console.log('reward_transactions ' , reward_transactions)
        dispatch(RewardTransactions({ skip: pageNo, limit: limit })); 
    }   
    const [page,setpage] = useState(1);

  return (
    <div> 
            <div className="table-area"> 
                <div className="card border-0 bg-transparent"> 
                <h4 className='my-3 text-dark'>Reward List</h4>
                    <div className='table-responsive'>
                        <table className="table table-hover text-white">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">User</th>
                                <th scope="col">Business</th>
                                <th scope="col">Left Business</th>
                                <th scope="col">Right business</th>
                                <th scope="col">Reward</th>
                                <th scope="col">Credit Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                reward_transactions?.reward_list && reward_transactions?.reward_list.length > 0 ?
                                reward_transactions?.reward_list.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{skip + i  + 1}</td>   
                                                    <td>{`${row.user_id}`}</td>
                                                    <td>{row.team_business}</td>   
                                                    <td>{row.left_business}</td>   
                                                    <td>{row.right_business}</td>   
                                                    <td className={row.amount > 0 ? `text-success` : `text-danger`}>{row.amount}{currency} <br/>
                                                    {row.reward_name}
                                                    </td>
                                                    
                                                    <td>{row.date}</td>   
                                                </tr>
                                            )
                                        })
                                    : 'No Fund Transations Found.' 
                            } 
                            </tbody>
                        </table> 
                        <div className='pagination'>
                            <Pagination className="mx-auto"
                                defaultActivePage={1}
                                onPageChange={pageChange}
                                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                totalPages={Math.ceil(reward_transactions?.total / limit)}
                        />  
                        </div>     
                    </div>
                </div>
            </div>
    </div>
  )
}

export default RewardList