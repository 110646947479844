import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { history } from "../history.reducer";

import { reducer as formReducer } from "redux-form";
import loader from "./loader.reducer";
import persist from "./persist.reducer";
import menu from "./menu.reducer";
import kycData from "./kycData.reducer";
import restaurant from "./restaurant.reducer";

import dashboard from "./dashboard.reducer";
import income from "./income.reducer";
import packagedata from "./package.reducer";
import ticket from './ticket.reducer'
import deposit from "./deposit.reducer";
import wallet from "./wallet.reducer";
import withdraw  from "./withdraw.reducer";
import swap from "./swap.reducer";
import fund from "./fund.reducer";
import roi  from  "./roi.reducer";
const appReducer = combineReducers({
    loader: loader,
    form: formReducer,
    persist: persist,
    restaurant: restaurant,
    menu: menu,
    kycData: kycData,
    dashboard: dashboard,
    income : income,
    packagedata:packagedata,
    router: connectRouter(history),
    ticket:ticket,
    deposit:deposit,
    wallet:wallet,
    withdraw,withdraw,
    swap:swap,
    fund : fund,
    roi : roi,
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
