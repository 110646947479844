import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { sendFund } from '../../../redux/actions/fund.action';
import { checkSponser } from '../../../redux/actions/user.action';

const SendFund = ({history}) => {
    const  dispatch = useDispatch();
    const [getnews,setNews] = useState();
    const [userId , setuserId] = useState('');
    const [amount , setAmount] = useState('');
    const check_sponser  = (sponser_id) => {
        // console.log('called' , sponser_id)
        dispatch(checkSponser(sponser_id))
        setuserId(sponser_id)
    }


    const handleSubmitForm = (e) => { 
        e.preventDefault();
        var data ={
            user_id:userId,
            amount : amount,
        }
        dispatch(sendFund(data));
        setuserId('')
        setAmount('')
    }

    
    return (
        <div>
            <div className="table-area" > 
                <div className="card border-0 bg-transparent"> 
                <h4 className='my-3 text-danger'>Send Fund</h4>
                    <div className='table-responsive col-md-6'>
                        <form onSubmit={handleSubmitForm}>
                            <div className="form-group">
                                <label >User Id</label>
                                <input type="text" class='form-control' onChange={(e) => {check_sponser(e.target.value)}} name="user_id" />
                            </div>
                            <div className="form-group">
                                <label>Amount</label>
                                <input type="number" class='form-control' name="amount" onChange={(e) => {setAmount(e.target.value)}} value={amount} />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendFund;