import * as types from '../actions/_types';

const initialState = {
  restaurant: {},
  finance: {
    totalCount: 0,
    transactionList: []
  },
  reviews: {
    reviewList: [],
    totalCount: 0
  },
  noti: {
    allData: [],
    totalCount: 0
  },
  workingHours: []
};

export default function restaurant(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_RESTAURANT_PROFILE:
      return { ...state, restaurant: action.payload };
        
    case types.SAVE_FINANCE_LIST:
      return { ...state, finance: action.payload };
     
    case types.SAVE_REVIEWS_LIST:
        return { ...state, reviews: action.payload };
  
    case types.SAVE_NOTIFICATION:
        return { ...state, noti: action.payload };
  
    case types.SAVE_WORKINGTIMES: 
        return { ...state, workingHours: action.payload };
    
    default:
      return state;
  }
}

