import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getUserDetails,kycAction,kycUsersDetails } from '../../../redux/actions/dashboard.action';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
const KYCrejected = () => {
    const [kycData,setkycData] = useState(); 
    const [open, setOpen] = useState(false);
    const [img, setimg] = useState('');
    const [open2, setOpen2] = useState(false);
    const [rejectId, setrejectId] = useState();
    const [remark, setremark] = useState();

    const user = useSelector(state => state.dashboard.user);
    const kyc = useSelector(state => state.kycData.kyc);

    const onOpenModal = (imgName) => {
        setimg(imgName)
        setOpen(true);
    };
    const onCloseModal = () => setOpen(false);
    const onOpenModal2 = (id) => {
        setrejectId(id);
        setOpen2(true);

    };    
    const onCloseModal2 = () => setOpen2(false);

    const dispatch = useDispatch();
    const data = {
        kyc_status: 'REJECTED'
    };
    const actionRes = (setValue,id) =>{
        const actionData = {kyc_status:setValue,user_id : id}
        dispatch(kycAction(actionData));
    }
    const actionreject = (setValue) =>{
        const actionData = {kyc_status:setValue,user_id : rejectId,remarks:remark}
        dispatch(kycAction(actionData));
        setOpen2(false)
    }
    useEffect(() => {
        dispatch(kycUsersDetails(data))
        dispatch(getUserDetails())
        setkycData(kyc);
    }, [open2])
    
    return (
        <div>
            <div className="row pra">
                <div>
                    <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > KYC Details (Rejected)</h4>
                </div>
            </div>
            <div className="table-area"> 
                <div className="card border-0 bg-transparent"> 
                    <h4 className='my-3'> KYC Details</h4>
                    <div className='table-responsive'>
                        <table className="table table-hover text-white">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">Name </th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Identity Document Type</th>
                                    <th scope="col">ID Number</th>
                                    <th scope="col">Document Back</th>
                                    <th scope="col">Document Front</th>
                                    <th scope="col">Signature Image</th>
                                    <th scope='col'> KYC Status</th>
                                    <th scope='col'>Remark</th>
                                    {/* <th scope='col'>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            { kyc?.users &&  kyc.users.length > 0 ?
                                kyc.users.map((row,i) => {
                                    return (
                                        <tr>
                                            <th scope="row">{i+1}</th>
                                            <td>{row._id}</td> 
                                            <td>{row.firstname + ' ' + row.lastname}</td>
                                            <td>{row.email}</td>
                                            <td>{row.documentType}</td>
                                            <td>{row.documentNumber}</td>
                                            <td><img src={row.documentBack} onClick={() => onOpenModal(row.documentBack)} width='200' /></td>
                                            <td><img src={row.documentFront} onClick={() => onOpenModal(row.documentFront)} width='200' /></td>
                                            <td><img src={row.signatureImage} onClick={() => onOpenModal(row.signatureImage)} width='200' /></td>
                                            <td>{row.kycStatus}</td>
                                            <td>{row.remarks}</td>

                                            {/* <td><a className='btn btn-sm table-btn bg-success' onClick={() => {actionRes('APPROVED',row._id)}}>Approve</a> | <a className='btn btn-sm table-btn bg-danger' onClick={() => {onOpenModal2(row._id)}}>Reject</a></td> */}

                                        </tr>
                                    )
                                })
                            : 'KYC Users Not  Found.'  } 
                            </tbody>
                        </table>  
                        <Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
                            <div className='container'>
                            <div className='row deposit-modal'>
                                <div className='col-md-12'>
                                <img src={img} /> 
                                </div>
                            </div>
                            </div>
                        </Modal> 
                        <Modal open={open2} onClose={onCloseModal2}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
                            <div className='container'>
                                <div className='row deposit-modal'>
                                    <div className='col-md-12'>
                                        <textarea id="remark" name="remark" rows="3" cols="30" onChange={(e) => setremark(e.target.value)}>{remark}</textarea>
                                        <a className='btn btn-sm table-btn bg-danger mt-5' onClick={() => {actionreject('REJECTED')}}>Reject</a>                            
                                    </div>
                                </div>
                            </div>
                        </Modal> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KYCrejected