import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getUserDetails,kycAction,kycUserMoreDetail } from '../../../redux/actions/dashboard.action';

const KycMoreDetail = ({ match: { params: { id } } }) => {
    const [kycData,setkycData] = useState(); 
    const user = useSelector(state => state.dashboard.user);
    const kyc = useSelector(state => state.kycData.kyc_docs);
    // console.log('kyc_docs',kyc.user);
    const dispatch = useDispatch();
    const data = {
        user_id: id
        
   };
    useEffect(() => {
        
        dispatch(kycUserMoreDetail(data))
        dispatch(getUserDetails())
    setkycData(kyc);

    }, [])
 
    const actionRes = (setValue) =>{
        const actionData = {kyc_status:setValue,user_id : id}
        dispatch(kycAction(actionData))

    }
   
    const tdData = () => {
        return(
            <tr>
                <td>{kyc?.user._id}</td> 
                <td>{kyc?.user.firstname + ' ' + kyc?.user.lastname}</td>
                <td>{kyc?.user.documentType}</td>
                <td>{kyc?.user.documentNumber}</td>
                <td><img src={kyc?.user.documentBack} width='200' /></td>
                <td><img src={kyc?.user.documentFront} width='200' /></td>
                <td><img src={kyc?.user.signatureImage} width='200' /></td>

                <td><a className='btn btn-sm table-btn bg-success' onClick={() => {actionRes('APPROVED')}}>Approve</a> | <a className='btn btn-sm table-btn bg-danger' onClick={() => {actionRes('REJECTED')}}>Reject</a></td>
            </tr>
            );
    }
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > KYC DOCUMENTS  <span className='text-primary'> Status: ({kyc?.user && kyc.user != undefined ?kyc?.user.kycStatus :' '})</span></h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
                <h4 className='my-3'> KYC DOCUMENTS</h4>
                <div className='table-responsive'>


                    <div >
                        <div></div>
                        <div></div>
                    </div>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                                <th scope="col">User ID</th>
                                <th scope="col">Name </th>
                                <th scope="col"> Document Type</th>
                                <th scope="col">ID Number</th>
                                <th scope="col">Document Back</th>
                                <th scope="col">Document Front</th>
                                <th scope="col">Signature Image</th>

                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                                {kyc?.user && kyc.user != undefined ? tdData()
                                
                            :' Empty'}
                        </tbody>
                    </table>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default KycMoreDetail