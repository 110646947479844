import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { startLoader, stopLoader } from './loader.action';
import { reset } from 'redux-form';
import { AuthorizationHeader, SimpleHeader } from './header.action'

const saveUserDetail = (user, token) => {
    console.log('before persist ', user)
    return {
        type: types.SAVE_LOGIN_USER_DETAIL,
        payload: { user, token }
    }
}

const saveIsForgot = (data) => {
    return {
        type: types.IS_FORGOT,
        payload: data
    }
}

const saveLogout = () => {
    return {
        type: types.LOGOUT,
    }
}

export const register = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        const options = SimpleHeader;
        return apiService.registerApi(data, options).then((res) => {
            console.log(res)
            if (res.data.success == 1) {

                history.push('/verifyAccount/' + data.email);
                toast.success('Sponser Name ' + res.data.name);
                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const resentOtp = (email) => (dispatch, getState) => {
    try {
        const options = SimpleHeader;
        return apiService.resentOtpApi({ email: email }, options).then((res) => {
            console.log(res)
            if (res.data.success == 1) {
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const verifyEmail = (data, history) => (dispatch, getState) => {
    try {
        const options = SimpleHeader;
        return apiService.verifyEmailApi(data, options).then((res) => {
            if (res.data.success == 1) {
                // toast.success(res.data.message);
                res = res.data.data;
                console.log('user data check',res.accessToken);
                dispatch(saveUserDetail(res, res.accessToken));
                dispatch(reset('LoginForm'));
                toast.success('Account Verified Successfully');
                // history.push('/user/dashboard');
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
export const checkSponser = (sponserId, history) => (dispatch, getState) => {
    try {
        const options = SimpleHeader;
        return apiService.checkSponserApi({ sponser_id: sponserId }, options).then((res) => {
            console.log(res)
            if (res.data.success == 1) {
                toast.success('Sponser Name ' + res.data.name);
            } else {
                toast.error('Invalid Sponser ID');
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const login = (data, history) => (dispatch, getState) => {
    try {
        console.log('his',history)
        dispatch(startLoader());

        const options = SimpleHeader;
        return apiService.loginApi(data, options).then((res) => {
            if (res.data.success == 1) {
                res = res.data;
                dispatch(saveUserDetail(res, res.admin.accessToken));
                dispatch(reset('LoginForm'));
                toast.success('Logged in successfully.');
                history.push('/user/dashboard');
                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            toast.error('Try again later');

            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const forgotPasswordFn = (data, history) => (dispatch, getState) => {
    try {
        console.log('forhis', history)
        dispatch(startLoader());
        delete data.passwordResetToken;
        return apiService.forgotApi(data).then((res) => {
            console.log("my console", res)
            // res = res.data.data.otp;
            toast.success(res.data.message);
             dispatch(reset('ForgotPasswordForm'));
            //  dispatch(saveIsForgot({ isForgotPassword: false, passwordResetToken: ""  }));
            dispatch(stopLoader());
            history.push('/resetPassword');
            console.log('reached')
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const verifyOtpFn = (history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        let { persist: { forgotPass } } = getState();
        forgotPass.otpCode = forgotPass.otpCode.toString();
        delete forgotPass.passwordResetToken;

        return apiService.verifyOtpApi(forgotPass).then((res) => {
            res = res.data.data.passwordResetToken;
            toast.success('OTP has been confirmed.');
            dispatch(reset('VerifyOtpForm'));
            dispatch(saveIsForgot({ otpCode: "", isForgotPassword: false, countryCode: "", mobileNumber: "", passwordResetToken: res }));
            history.push("/resetPassword");
            dispatch(stopLoader());
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const resetPassword = (data, history) => (dispatch, getState) => {
    try {
        let { persist: { forgotPass: { passwordResetToken } } } = getState();

        delete data.confirm_password;
        data.passwordResetToken = passwordResetToken;

        dispatch(startLoader());
        return apiService.passwordResetApi(data).then((res) => {
            // console.log('new resp',res);
            // res = res.data.data.passwordResetToken;
            toast.success('Password has been reset succussfully.');
            dispatch(reset('ResetPasswordForm'));
            // dispatch(saveIsForgot({ otpCode: "", isForgotPassword: false, countryCode: "", mobileNumber: "", passwordResetToken: "" }));
            history.push("/");
            dispatch(stopLoader());
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const changePassword = (data) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);
        delete data.confirm_password;

        return apiService.changePasswordApi(data, options).then((res) => {
            toast.success('Password has been changed succussfully.');
            dispatch(reset('ChangePasswordForm'));
            dispatch(stopLoader());
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

export const logoutUser = (history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
        dispatch(saveLogout());
        history.push('/');
        dispatch(stopLoader());
    } catch (error) {
        console.log('Logout err', error);
        dispatch(stopLoader());
    }
}