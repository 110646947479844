import React, { useState } from "react";
import { Form } from 'semantic-ui-react';
import { reduxForm, Field } from "redux-form";
import { InputField } from '../../../components/Inputs';
import { HiMail} from 'react-icons/hi'
import { BiKey } from 'react-icons/bi'
import { required } from 'redux-form-validators';
import { AiFillEye } from "react-icons/ai";
import { Link } from 'react-router-dom'


const LoginForm = ({ handleSubmit }) => {
    const [ passwordType, setPasswordType ] = useState("password");
    const handleSubmitForm = () => {
        handleSubmit();
    }

    const passwordTypeFn = () => {
        passwordType === "password"? setPasswordType("text"):setPasswordType("password"); 
    
    }

    return (
        <>
            <Form onSubmit={handleSubmitForm}>
                <div className="panel-body">
                    <div className="details password-form">
                    <div className="form-group">
                        <div className="label-area">
                        {/* <label className="label">Email:</label> */}
                        </div>
                        <div className="row-holder">
                        <div className="">
                            <Field component={InputField} type="email" name="email" className="form-control" placeholder="Email" validate={[required()]} />
                        </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-area">
                            {/* <label className="label">Password:</label> */}
                        </div>
                        <div className="row-holder">
                        <div className="pass-eye-fields" style={{position: 'relative'}}>
                            <Field component={InputField} type={passwordType} name="password" className="form-control" placeholder="Password" validate={[required()]} />
                            <div className="pass__icon"  style={{
                                'paddingTop': '8px',
                                'fontSize': '25px',
                                'position': 'absolute', 
                                'right': '8px',
                                'top': '0px',}}
                            onClick={passwordTypeFn}> <AiFillEye /> </div>
                        </div>
                        </div>
                    </div>
                    <div className="form-group text-right">
                        <button className="btn btn-primary"> Login </button>
                    </div>
                    <div className="form-group">
                        <p className="text-center">
                        {/* <Link to="forgot_password" className="forgot-link">Forgot password?</Link> or 
                            <Link to="register" className="forgot-link">Sign Up</Link> or */}
                            {/* <Link to="resetPassword" className="forgot-link">ResetPassword</Link> */}
                        </p>
                    </div> 
                    </div>
                </div>
            </Form>
        </>
    );
};

export default reduxForm({ form: 'LoginForm', enableReinitialize: true })(LoginForm);