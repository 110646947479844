import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaRegUserCircle, FaUnlockAlt } from 'react-icons/fa'

import VerifyAccountForm from "./verifyAccountForm";
import logo from "../../../_assets/images/logo.png";
import { verifyEmail } from "../../../redux/actions/user.action";
import { withRouter } from "react-router";
import { PROJECT_NAME } from "../../../_constants";
// import "../../../_assets/css/login.css";
const VerifyAccount = ({ history, match: { params: { email } } }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
     let data = {email : email , otp :values.otp};
     values = { ...values, deviceType: 'WEB', deviceToken: 'WEB' };
    dispatch(verifyEmail(data, history));
  }
 
  return (
    <>
    <div className="wrapper login-page style-3" id="top">
      <div className="cp-container">
        <div className="col-md-12 card card-body p-0 ">
          <div className="row">
            <div className="col-md-6 bg-right text-center">
                <div className="register-logo"> 
                    <a href="/">
                      <img className="light" src={logo} style={{maxWidth : '125px',borderRadius:'10px',marginBottom: '20px'}}/>
                    </a>
                    <h5 className="page-title text-white">Welcome To {PROJECT_NAME} </h5>
                </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <div className="cp-body p-3 pb-4">
                    <h5 className="head-title">Account verification </h5>
                    <div className="panel panel-primary">
                      <VerifyAccountForm onSubmit={handleSubmit} email={email} />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default VerifyAccount;