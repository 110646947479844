import * as types from '../actions/_types';

const initialState = {
    fund_transactions:{}, 
    
};

export default function fund(state = initialState, action) {
  switch (action.type) {
    
    case types.SAVE_FUND_TRANSACTIONS:
      return { ...state,fund_transactions: action.payload };   
    case types.SAVE_CONVERTED_FUND_TRANSACTIONS:
      return { ...state , converted_fund_transactions: action.payload}
    default:
      return state;
  }
}

