import * as types from '../actions/_types';

const initialState = {
  menus: [],
  toggleMenu:false,
  singleMenu: {
    _id: '',
    menuName: '',
    imageUrl: '',
    availabilityStatus: false
  },
  showSingleMenu: {},
  hideModal: false,
  
};

export default function menu(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_MENUS:
      return { ...state, menus: action.payload };
    case types.SAVE_SINGLE_MENU:
      return { ...state, singleMenu: action.payload };
    case types.SAVE_SINGLE_MENU_FOR_SHOW:
      return { ...state, showSingleMenu: action.payload };
    case types.SAVE_SHOW_MODAL:
       return { ...state, hideModal: action.payload };
        
    case types.TOGGLE_MENU:
       return { ...state, toggleMenu: action.payload };
        

    default:
      return state;
  }
}

