import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { get_swap_single_transactions, get_user_swap_Token, update_swap } from '../../../redux/actions/swap.action';

const EditSwapTrans = (props,{history}) => {
    const dispatch = useDispatch();
    const user_swap_list = useSelector(state => state.swap.swap_single_trans.records);
    const [tokenName ,settokenName] = useState();
    const [buyPrice ,setbuyPrice] = useState();
    const [sellPrice ,setsellPrice] = useState();
    const [conAddress ,setconAddress] = useState();
    const [dailySell ,setdailySell] = useState();
    const [sellstatus ,setsellstatus] = useState();
    const [purchaseStatus ,setpurchaseStatus] = useState();
    const [changeState ,setchangeState] = useState(false);

    const dataId  = props.match.params.id;
    // useEffect(() =>{
    //     // dispatch(get_user_swap_Token({id:dataId}));
    //     // setValue();
    //     settokenName(user_swap_list?.tokenName);
    //     setbuyPrice(user_swap_list?.buyPrice);
    //     setsellPrice(user_swap_list?.sellPrice);
    //     setconAddress(user_swap_list?.contractAddress);
    //     setdailySell(user_swap_list?.dailysellQuantity);
    //     setsellstatus(true);
    //     setpurchaseStatus(true);
    //     setchangeState(true);

    
    // },[user_swap_list]);
    useEffect(() => {
        dispatch(get_swap_single_transactions({id:dataId}));

    },[])
    
    console.log('user_swap_list',user_swap_list);
    console.log('sellstatus sellstatus',sellstatus);
    const onHandelSubmit = (e) => {
            e.preventDefault();

            var data = {
                id: dataId,
                sellprice: sellPrice,
                buyPrice: buyPrice,
                dailysellQuantity: dailySell,
                sellstatus: sellstatus,
                purchaseStatus: purchaseStatus,
            }
            console.log('data data',data)
            dispatch(update_swap(data,history));
    }

    const  reviewBtn = () => {
        return(
            <div>
                {user_swap_list?.adminHash? ' ' :<button className='btn' style={{backgroundColor:'#28cd95',color:'#fff',float:'right'}}>Review</button>
}
            </div>
        );
    }
   
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > Swap Transaction</h4>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className="table-area"> 
                        <div className="card border-0 bg-transparent"> 
                        <h4 className='my-3'> Swap Transaction Deatils</h4>
                            <div className='table-responsive'>
                                <form onSubmit={onHandelSubmit}>
                                    <div className="form-group">
                                        <label for="">Amount</label>
                                        <input type="text" className="form-control"  placeholder="Amount" value={user_swap_list?.amount} readOnly />
                                    </div>
                                    <div className="form-group">
                                        <label for="address">Swap Token	</label>
                                        <input type="text" className="form-control" id="address"  placeholder="Swap Token" value={user_swap_list?.swaptoken} readOnly />
                                    </div>
                                    <div className="form-group">
                                        <label for="buy">Currency</label>
                                        <input type="text" className="form-control" id="buy"  placeholder="Currency" value={user_swap_list?.currency} readOnly/>
                                    </div>
                                    <div className="form-group">
                                        <label for="sell">Currency Amount</label>
                                        <input type="text" className="form-control" id="sell"   placeholder="Currency Amount" value={user_swap_list?.currency_amount} readOnly/>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label for="daily-sell">USD Amount</label>
                                        <input type="text" className="form-control" id="daily-sell"  placeholder="USD Amount" value={user_swap_list?.usd_amount} readOnly/>
                                    </div>
                                    <div className="form-group">
                                        <label for="daily-sell">Admin Hash</label>
                                        <input type="text" className="form-control" id="daily-sell"  placeholder="Admin Hash" value={user_swap_list?.adminHash} readOnly/>
                                    </div>
                                    <div className="form-group">
                                        <label for="daily-sell">Mode</label>
                                        <input type="text" className="form-control" id="daily-sell"  placeholder="Mode" value={user_swap_list?.mode} readOnly/>
                                    </div>
                                    {/* <div className="custom-control custom-switch mb-3">
                                        <input type="checkbox" className="custom-control-input" id="switch" checked={sellstatus} />
                                        <label className="custom-control-label" for="switch"><p className='ml-3 mt-1'>Sell Status</p></label>
                                    </div>
                                    <div className="custom-control custom-switch mb-3">
                                        <input type="checkbox" className="custom-control-input" id="switch1" value='ON' checked={purchaseStatus} />
                                        <label className="custom-control-label" for="switch1"><p className='ml-3 mt-1'>Buy Status</p></label>
                                    </div> */}
                                    
                                    {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                </form>   
                                {reviewBtn()} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default EditSwapTrans
