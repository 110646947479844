import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 



const saveRoiTransactions = (data) => {
    return {
        type : types.SAVE_ROI_TRANSACTIONS,
        payload:data
    }
}

const saveRewardTransactions = (data) => {
    return {
        type : types.SAVE_REWARD_TRANSACTIONS,
        payload:data
    }
}
export const RoiTransactions = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.RoiTransactionsApi(data,options).then((res) => { 
            console.log('resp is ',res.data)
            if(res.data.success == 1){
                dispatch(saveRoiTransactions(res.data));
                toast.success(res.data.message);
                dispatch(stopLoader());
            }else{
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('error is ' , error)
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('error is ' , error)
        console.log('Failed', error);
    }
} 
 


export const RewardTransactions = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.RewardTransactionsApi(data,options).then((res) => { 
            console.log('resp is ',res)
            if(res.data.success == 1){
                dispatch(saveRewardTransactions(res.data));
                toast.success(res.data.message);
                dispatch(stopLoader());
            }else{
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('error is ' , error)
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('error is ' , error)
        console.log('Failed', error);
    }
} 