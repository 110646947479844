import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import Login from "../../pages/public/login/Login";
import ForgotPassword from "../../pages/public/forgotPassword/ForgotPassword";
import ResetPassword from "../../pages/public/resetPassword/ResetPassword";
import Register from "../../pages/public/register/register";
import verifyAccount from "../../pages/public/verify_account/verifyAccount"

class PublicRoutes extends Component {
    render() {
        return (
            <div className="PublicArea__content">
                <Route path="/" component={Login} exact={true} />
                <Route path="/forgot_password" component={ForgotPassword} exact={true} />
                <Route path="/resetPassword" component={ResetPassword} exact={true} />
                <Route path="/register" component={Register} exact={true} />
                <Route path="/verifyAccount/:email" component={verifyAccount} exact={true} />
            </div>
        );
    }
}

export default withRouter(PublicRoutes);
