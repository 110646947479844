import * as types from '../actions/_types';

const initialState = {
  kyc: {
    kyc: [],
  },
  kyc_docs: {
    kyc_docs: {},
  },
  network:[],
  deposit:[],
  deposit_token_detail:[],
};

export default function kycData(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_USER_KYC_DETAIL:
      return { ...state, kyc: action.payload };
    case types.SAVE_USER_MORE_KYC_DETAIL:
      return { ...state, kyc_docs: action.payload };
    case types.SAVE_NETWORK_DETAILS:
        return { ...state, network: action.payload };
    case types.SAVE_DEPOSIT_DETAILS:
        return { ...state, deposit: action.payload };
    case types.SAVE_DEPOSIT_TOKEN_DETAILS:
      return {...state,deposit_token_detail:  action.payload}
    default:
      return state;
  }
}

