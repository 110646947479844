import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Dashboard from "../../pages/private/dashboard/Dashboard";
import Sidebar from "../../pages/private/dashboard/sidenav";
import Users from "../../pages/private/users/Users";
import Tempusers from "../../pages/private/tempusers/Tempusers";
import Blockchains from "../../pages/private/blockchains/Blockchains";
import Coin from "../../pages/private/coins/Coin";
import User_details from "../../pages/private/users/User_details";
import KYCform from "../../pages/private/kyc/KYCform";
import KYCtable from "../../pages/private/kyc/KYCdetails";
import KYCpending from "../../pages/private/kyc/KYCpending";
import KYCrejected from "../../pages/private/kyc/KYCrejected";
import KYCapproved from "../../pages/private/kyc/KYCapproved";
import KycMoreDetail from "../../pages/private/kyc/kycMoreDetail";
import Network from "../../pages/private/network/Network";
import Deposit from "../../pages/private/network/Deposit";
import CreateToken from "../../pages/private/network/CreateToken";
import EditToken from "../../pages/private/network/EditToken";
import UpdateAddress from "../../pages/private/network/UpdateAddress";
import AllDeposit from "../../pages/private/depositTransactions/AllDeposit";
import P2pTransaction from "../../pages/private/p2p/P2pTransaction";
import Withdraw from "../../pages/private/withdraw/Withdraw";
import SwapTokens from "../../pages/private/swap/SwapTokens";
import EditSwapToken from "../../pages/private/swap/EditSwapToken";
import SwapTransaction from "../../pages/private/swap/SwapTransaction";
import EditSwapTrans from "../../pages/private/swap/EditSwapTrans";
import CreateNews from "../../pages/private/news/CreateNews";
import News from "../../pages/private/news/News";
import EditNews from "../../pages/private/news/EditNews";
import P2pPosting from "../../pages/private/p2p/P2pPosting";
import P2pPostingDetails from "../../pages/private/p2p/P2pPostingDetails";
import Banner from "../../pages/private/banner/Banner";
import BannerTable from "../../pages/private/banner/BannerTable";
import P2pOrders from "../../pages/private/p2p/P2pOrders";
import ViewOrders from "../../pages/private/order/ViewOrders";
import PendingOrders from "../../pages/private/order/PendingOrders";
import CancelOrders from "../../pages/private/order/CancelOrders";
import FundHistory  from "../../pages/private/fund/fund_history";
import Sendfund from "../../pages/private/fund/send_fund";
import RoiList from "../../pages/private/roi/roi_list"
import PaidUsers from "../../pages/private/users/PaidUsers";
import TodayJoinings from "../../pages/private/users/todayJoinings"
import Income from "../../pages/private/income/income"
import Income_type from "../../pages/private/income/Income_type";
import ConvertedFundHistory from "../../pages/private/fund/converted_fund_history"
import RewardList from "../../pages/private/reward/reward_list"
class PrivateRoutes extends Component {
    
    render() {
        return (
            <div className="PublicArea__content header">
                <Header  ></Header> 
                <div  className="sidebar" id="sidebar">
                <Sidebar/>
                <div className="box">
                <Route path="/vendor/income" component={Income} exact={true} /> 
                <Route path="/vendor/income_type/:type/:today_records" component={Income_type} exact={true} /> 
                <Route path="/user/dashboard" component={Dashboard} exact={true} />
                <Route path="/vendor/dashboard" component={Dashboard} exact={true} />
                <Route path="/vendor/users" component={Users} exact={true} />
                <Route path="/vendor/paid_users" component={PaidUsers} exact={true} />
                <Route path="/vendor/today_joinings" component={TodayJoinings} exact={true} />
                <Route path="/vendor/user_details/:id" component={User_details} exact={true} />
                <Route path="/vendor/tempusers" component={Tempusers} exact={true} />
                <Route path="/vendor/blockchains" component={Blockchains} exact={true} />
                <Route path="/vendor/coins" component={Coin} exact={true} />
                <Route path="/vendor/kyc" component={KYCform} exact={true} />
                <Route path="/vendor/kycDetail" component={KYCtable} exact={true} />
                <Route path="/vendor/pending-kyc" component={KYCpending} exact={true} />
                <Route path="/vendor/approved-kyc" component={KYCapproved} exact={true} />
                <Route path="/vendor/rejected-kyc" component={KYCrejected} exact={true} />
                <Route path="/vendor/kyc-dosc/:id" component={KycMoreDetail} exact={true} />
                <Route path="/vendor/network" component={Network} exact={true} />
                <Route path="/vendor/deposit" component={Deposit} exact={true} />
                <Route path="/vendor/create-token" component={CreateToken} exact={true} />
                <Route path="/vendor/edit-token/:id" component={EditToken} exact={true} />
                <Route path="/vendor/UpdateAddress/:id" component={UpdateAddress} exact={true} />
                <Route path="/vendor/allDeposit" component={AllDeposit} exact={true} />
                <Route path="/vendor/p2p_trans" component={P2pTransaction} exact={true} />
                <Route path='/vendor/P2pPosting' component={P2pPosting} exact={true} />
                <Route path='/vendor/P2pPostingDetails/:id' component={P2pPostingDetails} exact={true} />
                <Route path='/vendor/P2pOrders' component={P2pOrders}  exact={true} /> 
                <Route path="/vendor/withdraw/:type" component={Withdraw} exact={true} />
                <Route path="/vendor/swaptoken" component={SwapTokens} exact={true} />
                <Route path="/vendor/editSwapToken/:id" component={EditSwapToken} exact={true} />
                <Route path="/vendor/swap_trans" component={SwapTransaction} exact={true} />
                <Route path="/vendor/edit_trans/:id" component={EditSwapTrans} exact={true} />
                <Route path='/vendor/create-news' component={CreateNews} exact={true} />
                <Route path='/vendor/news' component={News} exact={true} />
                <Route path="/vendor/edit-news" component={EditNews} exact={true} />
                <Route path="/vendor/banner" component={Banner} exact={true} />
                <Route path="/vendor/view-banner" component={BannerTable} exact={true} />
                <Route path="/vendor/view-trade-order" component={ViewOrders} exact={true} />
                <Route path="/vendor/pending-trade-order" component={PendingOrders} exact={true} />
                <Route path="/vendor/cancel-trade-order" component={CancelOrders} exact={true} />
                <Route path="/vendor/completed-trade-order" component={ViewOrders} exact={true} />

                
                <Route path="/vendor/fund/history" component={FundHistory} exact={true} />
                <Route path="/vendor/fund/send_fund" component={Sendfund} exact={true} />
                <Route path="/vendor/Roi/roi_list" component={RoiList} exact={true} />
                <Route path="/vendor/fund/converted_fund_history" component={ConvertedFundHistory} exact={true} />
                <Route path="/vendor/reward_list" component={RewardList} exact={true} />
                
                
                <Footer />
                </div>
                </div>
                
            </div>
        );
    }
}

export default withRouter(PrivateRoutes);
