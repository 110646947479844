import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails} from '../../../redux/actions/dashboard.action';
import { P2pOrderAction, P2pOrders_function } from '../../../redux/actions/wallet.action';
import { Pagination, Icon } from 'semantic-ui-react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
const P2pOrders = () => {
    const [kycData,setkycData] = useState(); 
    const [rejectId, setrejectId] = useState();
    const [remark, setremark] = useState();
    const [open2, setOpen2] = useState(false);

    const user = useSelector(state => state.dashboard.user);
    const UsersData = useSelector(state => state.wallet.p2p_orders);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(P2pOrders_function({limit:5,skip:0}))
        dispatch(getUserDetails())
    }, [open2]);


    const onOpenModal2 = (id) => {
        setrejectId(id);
        setOpen2(true);

    };    
    const onCloseModal2 = () => setOpen2(false);
    const actionRes = (setValue,id) =>{
        const actionData = {status:setValue,order_id : id}
        dispatch(P2pOrderAction(actionData));
    }
    const actionreject = (setValue) =>{
        const actionData = {status:setValue,order_id : rejectId,remarks:remark}

        console.log('actionData',actionData);
        dispatch(P2pOrderAction(actionData));
        setOpen2(false)
    }



    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }

    const [page,setpage] = useState(1);
    const [pageNum,setpageNum] = useState(0);

    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * 5);
        dispatch(P2pOrders_function({ skip: pageNo, limit: 5 })); 
        setpageNum(pageNo);
    }   
    var skipdata = page-1;

    const showBtn = (id) => {
        return(<div>
            <button className='btn btn-sm table-btn bg-success' onClick={() => {actionRes('APPROVED',id)}} >Approve</button> | <button className='btn btn-sm table-btn bg-danger' onClick={() => {onOpenModal2(id)}}>Reject</button>
        </div>)
    }

    const usersData = () =>{

        return (
            UsersData?.orders &&  UsersData.orders.length > 0 ?
                UsersData.orders.map((row,i) => {
                    return(
                        <tr>
                            <th scope="row">{i+1}</th>
                            <td>{row.buyer_id?.[0]?.username}</td>
                            <td>{row.buyer_id?.[0]?.firstname? row.buyer_id?.[0]?.firstname + ' ' +row.buyer_id?.[0]?.lastname : ' '}</td>
                            <td>{row.user_id?.[0]?.username}</td>
                            <td>{row.user_id?.[0]?.firstname? row.user_id?.[0]?.firstname + ' ' +row.user_id?.[0]?.lastname : ' '}</td>
                            <td>{row.UserPaymentMethodID?.[0]?.PaymentMethodFields.map((val) => {
                                return(
                                    <span>
                                        {val.field.replace("_",' ').toUpperCase() }: <span className='font-weight-bold'>{val.value}</span> <br />
                                    </span>
                                )
                            })}</td>

                            <td>{row.token_id?.[0].coinName}</td>
                            <td>{row.quantity}</td>
                            <td>{row.status}</td>
                            <td>{secondsToHms(row.createdAt)}</td>
                            <td>{row.status != 'APPROVED' && row.status != 'REJECTED' ? showBtn(row._id):' '}</td>
                        </tr>
                    );
                })
        : ' Data Not  Found.');
    }

  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > P2p Orders</h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> P2p Orders</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Buyer </th>
                                <th scope="col">Buyer Name</th>
                                <th scope="col">Seller </th>
                                <th scope="col">Seller Name</th>
                                <th scope="col">User Payment Method </th>
                                <th scope='col' >Coin Name</th>
                                <th scope='col'>Quantity</th>
                                <th scope='col'>Status</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData()}
                        </tbody>
                    </table>  
                    <div className='pagination'>
                    <Pagination className="mx-auto"
                        defaultActivePage={1}
                        onPageChange={pageChange}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={Math.ceil(UsersData?.transactionsCount / 5)}
                />  
                    </div>   
                </div>
            </div>
        </div>
        <Modal open={open2} onClose={onCloseModal2}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
            <div className='container'>
                <div className='row deposit-modal'>
                    <div className='col-md-12'>
                        <label>Remark</label>
                        <textarea id="remark" name="remark" rows="3" cols="30" onChange={(e) => setremark(e.target.value)}>{remark}</textarea>
                        <a className='btn btn-sm table-btn bg-danger mt-5' onClick={() => {actionreject('REJECTED')}}>Reject</a>                            
                    </div>
                </div>
            </div>
        </Modal> 
    </div>
  )
}

export default P2pOrders