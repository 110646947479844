import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const AuthGuard = ({ component: Component, ...rest }) => {
    const isSignedIn = useSelector(state => state.persist.isSignedIn);
    const isAuthenticated = isSignedIn;

    return (
        <Route {...rest} render={props => isAuthenticated === true ?
            (<Component {...props} />) :
            (<Redirect to={{ pathname: "/", state: { from: props.location } }} />)}
        />
    );
};

export default AuthGuard;

