import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SubmitWithdrawRequest, withdraw_requests , allow_withdraw } from '../../../redux/actions/withdraw.action'; 
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
// import Web3 from 'web3';
// import WalletConnectProvider from '@walletconnect/web3-provider';


import { Pagination, Icon } from 'semantic-ui-react';
import { currency } from '../../../_constants'; 

const Withdraw = ({ match: { params: { type } } }) => {
   
    let page_limit = 10
    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * page_limit);
        setSkip(pageNo)
        dispatch(withdraw_requests({ skip: pageNo, limit: page_limit , type : type })); 
    }   
    const [skip,setSkip] = useState(0);
    const [open2, setOpen2] = useState(false);
    const [open, setOpen] = useState(false);
    const [userId, setuserId] = useState();
    const [tokenId, settokenId] = useState();
    const [hash, sethash] = useState();
    const [fieldId, setfieldId] = useState();

    const [remark, setremark] = useState();
    const onCloseModal2 = () => setOpen2(false);
    const onOpenModal2 = (val) => {
        console.log('its val ' , val)
        setuserId(val?.user_id);
        // settokenId(val?.token_id[0]);
        setfieldId(val?._id);
        // console.log('val',val?.token_id[0]);
        setOpen2(true);
    }
    const onCloseModal = () => setOpen(false);
    const onOpenModal = (val) => {
        setuserId(val?.user_id);
        // settokenId(val?.token_id[0]);
        setfieldId(val?._id);

        // console.log('val',val?._id[0]);
        setOpen(true);
    }
    const withdraw_request = useSelector(state => state.withdraw.requests);
    console.log('withdraw_request',withdraw_request);
    // const [web3, setWeb3] = useState(null);
    const [userAccount, setUserAccount] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(withdraw_requests({limit:page_limit,skip:skip, type : type}))
        // async function initWeb3() {
        //     if (typeof window.ethereum !== 'undefined') {
        //       try {
        //         // Request account access if needed
        //         await window.ethereum.request({ method: 'eth_requestAccounts' });
      
        //         // Initialize Web3.js
        //         const Web3 = require('web3');
        //         const web3 = new Web3(window.ethereum);
      
        //         // Get the user's Ethereum account address
        //         const accounts = await web3.eth.getAccounts();
        //         const userAccount = accounts[0];
        //         console.log('uu ' , userAccount)
        //         setWeb3(web3);
        //         setUserAccount(userAccount);
        //       } catch (error) {
        //         console.error('User denied account access:', error);
        //       }
        //     } else {
        //       console.error('MetaMask or another Ethereum wallet not found.');
        //     }
        //   }
      
        // initWeb3();
    },[type,open,open2]);

    const actionreject = () => {
        var data = {
            withdraw_id:fieldId,
            user_id:userId,
            remarks:remark,
            // token_id:tokenId,
            status:2,
            // hash:hash,
        }
        console.log('data',data);
        dispatch(SubmitWithdrawRequest(data));
        onCloseModal2();
        dispatch(withdraw_requests({limit:page_limit,skip:skip, type : type}))
    }
    const actionapprove = () => {
        var data = {
            withdraw_id:fieldId,
            user_id:userId,
            // remarks:remark,
            token_id:tokenId,
            status:1,
            hash:hash,
        }
        // console.log('data',data);
        dispatch(SubmitWithdrawRequest(data));
        onCloseModal()
        dispatch(withdraw_requests({limit:page_limit,skip:skip, type : type}))
    }
    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }
    // console.log('fieldId',fieldId);

    const  actionbtn = (val) => {
        return(
            <div>
                <a className='btn btn-sm table-btn bg-success' onClick={() => onOpenModal(val)} >Approve</a> |
                <a className='btn btn-sm table-btn bg-danger' onClick={() => {onOpenModal2(val)}}>Reject</a>
            </div>
        )
    }

    const allowWithdrawBtn = (val) => {
        if(val?.status == 0){
            if(val?.admin_approve_status != 1){
                return(
                    <div>
                        <a className='btn btn-sm table-btn bg-success' onClick={() => allowWithdraw(val)}>Allow</a> 
                    </div>
                )
            } 
        } 
    }
    const allowWithdraw = (val)=>{
        let text = "Are You Sure to Allow this Withdraw";
        if (window.confirm(text) == true) { 
            var data = {
                withdraw_id:val?._id,
            }
            dispatch(allow_withdraw(data))
            // window.location.reload();
            dispatch(withdraw_requests({limit:page_limit,skip:skip, type : type}))
        } 
    }
    const formData = () => {
        return withdraw_request?.transactions?.map((val,i) => {
            return(
                <tr key={i}>
                    <th scope="row">{skip + i  + 1}</th>
                    <td>{val?.user?.[0]?.user_id}<br/>{val?.user?.[0]?.email}</td> 
                    <td>${val.amount}</td>
                    <td>${val.payable_amount}</td>
                    <td>{val?.user?.[0]?.wallet_address}<br/>
                    {val.remark}
                    </td>
                    <td>{val.type.replace('_' , ' ').toUpperCase()}</td>
                    <td>{withdraw_status(val.status)}
                    </td>
                    <td>
                    Admin Status: {val?.admin_approve_status == 1 ? 'Allowed' : 'Not Allowed'} <br/>
                    { allowWithdrawBtn(val)}
                    </td> 
                    <td>{secondsToHms(val.createdAt)}</td>
                    <td>{val.status == 0 ? actionbtn(val) : ''}</td>

                </tr>
            );
        })
    }

    let withdraw_status = (status) => {
        if(status == 0){
            return 'PENDING';
        }else if(status == 1){
            return 'APPROVED';
        }else if(status == 2){
            return 'REJECTED';
        }
    }
  return (
    <div> 
        <div className="card"> 
            <div className='card-header'> Withdraw Deatils {currency}{withdraw_request?.total_withdraw_amount}</div>
            <div className='table-responsive'>
                <table className="table table-hover text-white">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">User Name</th> 
                        <th scope="col">Amount</th>
                        <th scope="col">Payable amount</th>
                        <th scope="col">Wallet Address</th>
                        <th scope="col">Type</th> 
                        <th scope="col">Status</th> 
                        <th scope="col">Date</th>
                        <th scope="col">{type == 'investment_withdraw' ? 'Action' : ''}</th>
                        

                        </tr>
                    </thead>
                    <tbody>
                        {formData()}
                    </tbody>
                </table>  
                <div className='pagination'>
                    <Pagination className="mx-auto"
                        defaultActivePage={1}
                        onPageChange={pageChange}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={Math.ceil(withdraw_request?.transactionrecords / page_limit)}
                />  
                </div>  
                <Modal open={open2} onClose={onCloseModal2}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
                    <div className='container'>
                        <div className='row deposit-modal'>
                            <div className='col-md-12 d-none'>
                                <label>Hash</label>
                                <input type='text' id="hash" className='form-control' name="hash" style={{border: '2px solid #ccc'}}  onChange={(e) => sethash(e.target.value)} />
                            </div>
                            <div className='col-md-12'>
                                <label>Remark</label>
                                <textarea id="remark" name="remark"  className='form-control'rows="3" cols="30" onChange={(e) => setremark(e.target.value)}>{remark}</textarea>
                                
                            </div>
                            <a className='btn btn-sm table-btn bg-danger mt-5' onClick={() => {actionreject('REJECTED')}}>Reject</a>                            
                        </div>
                    </div>
                </Modal> 
                <Modal open={open} onClose={onCloseModal}  animationDuration='600' ariaDescribedby={'bottom'} classNames={{ modal: 'modal-design',}} center>
                    <div className='container'>
                        <div className='row deposit-modal'>
                            <div className='col-md-12'>
                                <label>Hash</label>
                                <input type='text' id="hash" className='form-control' name="hash"  onChange={(e) => sethash(e.target.value)} />
                            </div>
                            <div className='col-md-12 d-none'>
                                <label>Remark</label>
                                <textarea id="remark" name="remark" rows="3" className='form-control' cols="30" onChange={(e) => setremark(e.target.value)}>{remark}</textarea>
                                
                            </div>
                            <a className='btn btn-sm table-btn bg-success mt-5' onClick={() => {actionapprove('Approve')}}>Approve</a>                            
                        </div>
                    </div>
                </Modal>   
            </div>
        </div>
    </div>
  )
}

export default Withdraw