import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 
 
const saveWithdrawRequests = (data) => {
    return {
        type : types.SAVE_WITHDRAW_REQUESTS,
        payload:data
    }
} 
  
export const withdraw_requests = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.withdrawRequestsListApi(data, options).then((res) => { 
            console.log('usdt response' , res.data) 
            if(res.data.success == 1){
                dispatch(saveWithdrawRequests(res.data));
                // toast.success(res.data.balance);
            }else{ 
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const SubmitWithdrawRequest = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.withdrawRequestApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
 


export const allow_withdraw = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.allowWithdrawApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}