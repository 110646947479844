import * as types from '../actions/_types';

const initialState = {
  isSignedIn: false,
  userDetails: {
    restaurantName: ''
  },
  token: "",
  forgotPass: {
    otpCode: "",
    isForgotPassword: false,
    email:"",
    passwordResetToken: ""
  }
};

export default function persist(state = initialState, action = {}) {
  // console.log('persist called ' ,action.type)
  if(action.type == '[USER] SAVE_LOGIN_USER_DETAIL'){
    console.log('persist called ' ,action)
  
  }
  switch (action.type) {
    
    case types.SAVE_LOGIN_USER_DETAIL:
      return { ...state, isSignedIn: true, userDetails: action.payload.user, token: action.payload.token };

    case types.IS_FORGOT:
      return { ...state, forgotPass: action.payload }

    case types.LOGOUT:
      return initialState;
  
    default:
      return state;
  }
}

