import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader,AuthorizationHeaderImage } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 

const save_swap_Token = (data) => {
    return {
        type: types.SAVE_SWAP_TOKEN_LIST,
        payload: data
    }
}

export const get_swap_Token = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.swap_token_api(data, options).then((res) => { 
            res = res.data;
            dispatch(save_swap_Token(res));
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const save_user_swap_Token = (data) => {
    return {
        type: types.SAVE_SWAP_USER_TOKEN,
        payload: data
    }
}

export const get_user_swap_Token = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.swap_user_token_api(data, options).then((res) => { 
            res = res.data;
            dispatch(save_user_swap_Token(res.records));
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const update_swap = (data, history) => (dispatch, getState) => {
    try {
        dispatch(startLoader());
// console.log('data',data);
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.update_swap_api(data, options).then((res) => {

            // console.log('outksdfbkjsd ', res.data)
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                history.push('/vendor/swaptoken');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}

const save_swap_Token_transactions = (data) => {
    return {
        type: types.SAVE_SWAP_TOKEN_LIST_TRANSACTION,
        payload: data
    }
}

export const get_swap_Token_transactions = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.swap_token_transaction_api(data, options).then((res) => { 
            res = res.data;
            dispatch(save_swap_Token_transactions(res));
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const save_swap_single_transactions = (data) => {
    return {
        type: types.SAVE_SWAP_SINGLE_TRANSACTION,
        payload: data
    }
}

export const get_swap_single_transactions = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.swap_single_transaction_api(data, options).then((res) => { 
            res = res.data;
            dispatch(save_swap_single_transactions(res));
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const create_news = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        console.log('function',data)
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.create_news_api(data, options).then((res) => { 
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                // history.push('/vendor/uploadproof');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}

const save_news = (data) => {
    return {
        type: types.SAVE_NEWS,
        payload: data
    }
}

export const get_news = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.news_api(data, options).then((res) => { 
            res = res.data;
            dispatch(save_news(res));
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const delete_news = (data) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        console.log('function',data)
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.delete_news_api(data, options).then((res) => { 
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                // history.push('/vendor/uploadproof');
                dispatch(get_news())
                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}
export const edit_news = (data,history) => (dispatch, getState) => {
    try { 
        dispatch(startLoader());    
        console.log('function',data)
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.edit_news_api(data, options).then((res) => { 
            if (res.data.success == 1) {
                
                toast.success(res.data.message);
                history.push('/vendor/news');

                dispatch(stopLoader());
            } else {
                toast.error(res.data.message);
                dispatch(stopLoader());
            }

        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());

        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());

    }
}