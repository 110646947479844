import React, { useState } from "react";
import { Form } from 'semantic-ui-react';
// import { reduxForm, Field } from "redux-form";
import { InputField } from '../../../components/Inputs';
import { HiMail} from 'react-icons/hi'
import { BiKey } from 'react-icons/bi'
import { reduxForm, Field } from "redux-form";
import { required } from 'redux-form-validators';
import { AiFillEye } from "react-icons/ai";
import { Link } from 'react-router-dom'

const RegisterForm = ({ handleSubmit , checkSponser}) => {
    const [ passwordType, setPasswordType ] = useState("password");
    // const [ sponserId, setSponserId ] = useState("ttt");
    const handleSubmitForm = () => {
        handleSubmit();
    }

    const passwordTypeFn = () => {
        if (passwordType === "password") {
            setPasswordType("text");
        } else {
            setPasswordType("password");
        }
    }
    const findSponser = (sponserid) => {
        checkSponser(sponserid)
    }

    return (
        <>

            <Form onSubmit={handleSubmitForm}>
                <div className="panel-body">
                    <div className="details password-form">
                    <div className="form-group">
                        <div className="label-area">
                        <label>Refferal Id:</label>
                        </div>
                        <div className="row-holder">
                        <div className="">
                            <Field component={InputField} type="text" name="sponser_id" onChange={(e) => {   findSponser(e.target.value)}}  className="form-control" placeholder="Refferal id" />
                        </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-area">
                        <label>Name:</label>
                        </div>
                        <div className="row-holder">
                        <div className="">
                            <Field component={InputField} type="text" name="name" className="form-control" placeholder="Name" validate={[required()]} />
                        </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-area">
                        <label>Phone:</label>
                        </div>
                        <div className="row-holder">
                        <div className="">
                            <Field component={InputField} type="number" name="phone" className="form-control" placeholder="Phone no." validate={[required()]} />
                        </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-area">
                        <label>Email:</label>
                        </div>
                        <div className="row-holder">
                        <div className="">
                            <Field component={InputField} type="email" name="email" className="form-control" placeholder="Email" validate={[required()]} />
                        </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-area">
                            <label>Password:</label>
                        </div>
                        <div className="row-holder">
                            <div className="pass-eye-fields">
                                <Field component={InputField} type={passwordType} name="password" className="form-control" placeholder="Password" validate={[required()]} />
                                <div className="pass__icon" onClick={passwordTypeFn}> <AiFillEye /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="Accept">
                    <span>
                        <input id="chTerms" name="chTerms" type="checkbox" required="required"/>    
                    </span>&nbsp;
                    I have read the  
                     <Link style={{cursor:"pointer",color:"red", fontSize:"16px"}} target="_blank" to="/terms">Terms &amp; Conditions 
                     </Link>

                </div>
                    <div className="form-group">
                    <button className="sbmit-btn"> Signup </button>
                    </div>
                    <div className="form-group">
                        <p className="text-center">
                            Already have an account?
                            <Link to="/" className="forgot-link">Sign in</Link>
                        </p>
                    </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default reduxForm({ form: 'RegisterForm', enableReinitialize: true }) (RegisterForm);