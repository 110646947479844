import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createToken, update_contractAddress, networkDetail } from '../../../redux/actions/dashboard.action';

const UpdateAddress = ({ match: { params: { id } } }) => {
    const  dispatch = useDispatch();
    const deposit = useSelector(state => state.kycData.deposit_token_detail?.tokenDetails?.[0]);
    const network = useSelector(state => state.kycData.network);
    const [networkName,setnetworkName] = useState();
    const [address,setaddress] = useState();

    useEffect(() => {
        dispatch(networkDetail())

    }, []);

    const handleSubmitForm = (e) => { 
        e.preventDefault();
        const submit = {
            tokenID: id,
            networkID: networkName,
            contractAddress: address,
        }
        dispatch(update_contractAddress(submit))
    }

    return (
        <div>
            <div className="row pra">
                <div>
                    <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > Update Address</h4>
                </div>
            </div>
            <div className="table-area" > 
                <div className="card border-0 bg-transparent"> 
                    <h4 className='my-3'> Update Address</h4>
                    <div className='row'>
                        <div className='table-responsive col-md-6'>
                            <form onSubmit={handleSubmitForm}>
                                
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Network</label>
                                    <select className="form-select" id="mySelect" onChange={(e) => {setnetworkName(e.target.value)}}  >
                                        <option  disabled selected> Select Address</option>
                                        {network?.networks?.map((row) => {
                                            return(
                                                <option  value={row._id}>{row?.Network}</option>
                                            );
                                        })}
                                    </select>                      
                                </div>
                                <div className="form-group">
                                    <label for="decimals">Contract Address</label>
                                    <input type="text" className="form-control" onChange={e => setaddress(e.target.value)}  placeholder="Contract Address"  required/>
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>   
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateAddress;