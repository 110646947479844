import React, { useEffect, useState } from 'react'
// import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Pagination, Icon } from 'semantic-ui-react';
import { get_swap_Token, get_swap_Token_transactions, get_user_swap_Token } from '../../../redux/actions/swap.action';
import { Link } from 'react-router-dom';
const useStyles  = makeStyles(() => ({
    
    Pagination:{
        '& .MuiPaginationItem-root':{
            color:'#000',
            display:'flex',
        li:{
            backgroundColor:'#fff',
        } ,   
        }
    }
}));
const SwapTransaction = () => {
    const dispatch = useDispatch();
    const [page,setpage] = useState(1);
    const [pageNum,setpageNum] = useState(0);

    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * 5);
        dispatch(get_swap_Token_transactions({ skip: pageNo, limit: 5 })); 
        setpageNum(pageNo);
    }   
    var skipdata = page-1;
    useEffect(() =>{
        dispatch(get_swap_Token_transactions({skip: 0, limit: 5}))
        
    },[]);

    const swap_list = useSelector(state => state.swap.swap_trans);
    console.log('swap_list',swap_list)
    const classes = useStyles();
// console.log('page',page);
function secondsToHms(c_date) {
    const isoStr = c_date;
    const date1 = new Date(isoStr); 
    const timestamp = date1.getTime();
    var date = new Date(timestamp);
    return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
}


  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > Swap Transaction</h4>
                </div>
            </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> Swap Transaction Deatils</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Swap Token</th>
                            <th scope='col'>Currency</th>
                            <th scope="col">Currency Amount</th>
                            <th scope="col">USD Amount</th>
                            <th scope="col">Block Hash</th>
                            <th scope="col">Mode</th>
                            <th scope="col">Time</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                       { swap_list?.records?.map((val,key) => {
                            return(
                                    <tr>
                                        <th scope="row">{pageNum+key+1}</th>
                                        <td>{val.amount}</td>
                                        <td>{val?.swaptoken}</td>
                                        <td>{val?.currency}</td>
                                        <td>{val?.currency_amount}</td>
                                        <td>{val?.usd_amount}</td>
                                        <td>{val?.blockHash}</td>
                                        <td>{val?.mode}</td>
                                        <td>{secondsToHms(val?.createdAt)} </td>
                                        <td><Link to={'/vendor/edit_trans/'+val?._id } className='btn btn-sm table-btn bg-success' >View</Link> </td>
                                    </tr>
                            );
                       })}
                        
                            
                        </tbody>
                    </table>   
                    <div className='pagination'>
                    <Pagination className="mx-auto"
                        defaultActivePage={1}
                        onPageChange={pageChange}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={Math.ceil(swap_list?.total / 5)}
                />  
                    </div>         
          </div>
            </div>
        </div>
    </div>
  )
}

export default SwapTransaction
