import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {  todayJoinings } from '../../../redux/actions/dashboard.action';
import { Pagination, Icon } from 'semantic-ui-react';
const Users = () => { 
    const user = useSelector(state => state.dashboard.user);
    const UsersData = useSelector(state => state.dashboard.todayJoinings);
    const dispatch = useDispatch();
    const limit = 10;
    const [page,setpage] = useState(1);
    const [skip,setSkip] = useState(0);
    const [search_type , setSearchType] = useState('user_id');
    const [search_value , setSearchValue] = useState('');
    useEffect(() => {
        dispatch(todayJoinings({limit:limit,skip:skip})) 
    }, [])
    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * limit); 
        setSkip(pageNo)
        if(search_value == ''){
            dispatch(todayJoinings({ skip: pageNo, limit: limit })); 
        }else{
            dispatch(todayJoinings({ skip: pageNo, limit: limit ,search_type : search_type , keyword :search_value})); 
        }
    }   
    const search_data = () => {
        dispatch(todayJoinings({ skip: 0, limit: limit ,searchParam : search_type , keyword :search_value})); 
    }
    const clear_data = () =>{
        dispatch(todayJoinings({ skip: 0, limit: limit ,searchParam : '' , keyword :''}));
    }

    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }
   
    const usersData = () =>{
        return (
            UsersData?.users &&  UsersData.users.length > 0 ?
                UsersData.users.map((row,i) => {
                    return(
                        <tr>
                            <th scope="row">{skip + i  + 1}</th> 
                            <td>{row.user_id}</td>
                            <td>{row.sponser_id}</td> 
                            <td>{row.email}</td>
                            <td><i class={row.isVerified ? 'check icon' : ' '} aria-hidden='true'></i></td>
                            <td>{secondsToHms(row.createdAt)}</td>
                            <td><Link to={`/vendor/user_details/${row.user_id}`}  >details</Link></td>
                        </tr>
                    );
                })
        : ' Users Not  Found.');
    }
    
    

  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > Today Joinings ( {UsersData.total})</h4>
            </div>
        </div> 
        <div className='row'>
            <div class="col-lg-3">
                <select className='form-control' onChange={e => {setSearchType(e.target.value)}}>
                    <option value="user_id">UserId</option>
                    <option value="sponser_id">Sponser ID</option>
                    <option value="email">Email</option>
                </select>
            </div>
            <div class="col-lg-3">
                <input type="text" onChange={e => setSearchValue(e.target.value)} className='form-control' name="value"  />
            </div>
            <div class="col-lg-3">
                <button type="button" onClick={search_data} className='btn bg-dark text-white'>Search</button>
                <button type="button" onClick={clear_data} className='btn bg-dark text-white'>Clear</button>
            </div>
        </div>
        <div className='table-responsive'>
            <table className="table table-hover text-white">
                <thead>
                    <tr>
                        <th scope="col">#</th> 
                        <th scope="col">UserId</th>
                        <th scope="col">Sponser</th> 
                        <th scope="col">Email</th>
                        <th scope="col">Verified</th>
                        <th scope="col">Created At</th>
                        {/* <th scope="col">Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {usersData()}
                </tbody>
            </table> 
            <Pagination className="mx-auto"
                defaultActivePage={1}
                onPageChange={pageChange}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                totalPages={Math.ceil(UsersData.total / limit)}
        />    
        </div>
    </div>
  )
}

export default Users