import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { get_user_swap_Token, update_swap } from '../../../redux/actions/swap.action';

const EditSwapToken = (props,{history}) => {
    const dispatch = useDispatch();
    const user_swap_list = useSelector(state => state.swap.user_swap_list);
    const [tokenName ,settokenName] = useState();
    const [buyPrice ,setbuyPrice] = useState();
    const [sellPrice ,setsellPrice] = useState();
    const [conAddress ,setconAddress] = useState();
    const [dailySell ,setdailySell] = useState();
    const [sellstatus ,setsellstatus] = useState();
    const [purchaseStatus ,setpurchaseStatus] = useState();
    const [changeState ,setchangeState] = useState(false);

    const dataId  = props.match.params.id;
    useEffect(() =>{
        // dispatch(get_user_swap_Token({id:dataId}));
        // setValue();
        settokenName(user_swap_list?.tokenName);
        setbuyPrice(user_swap_list?.buyPrice);
        setsellPrice(user_swap_list?.sellPrice);
        setconAddress(user_swap_list?.contractAddress);
        setdailySell(user_swap_list?.dailysellQuantity);
        setsellstatus(true);
        setpurchaseStatus(true);
        setchangeState(true);

    
    },[user_swap_list]);
    useEffect(() => {
        dispatch(get_user_swap_Token({id:dataId}));

    },[])
    
    console.log('user_swap_list',user_swap_list);
    console.log('sellstatus sellstatus',sellstatus);
    const onHandelSubmit = (e) => {
            e.preventDefault();

            var data = {
                id: dataId,
                sellprice: sellPrice,
                buyPrice: buyPrice,
                dailysellQuantity: dailySell,
                sellstatus: sellstatus,
                purchaseStatus: purchaseStatus,
            }
            console.log('data data',data)
            dispatch(update_swap(data,history));
    }
   
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > Swap Tokens</h4>
                </div>
            </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> Swap Tokens Deatils</h4>
                <div className='table-responsive'>
                <form onSubmit={onHandelSubmit}>
                        <div className="form-group">
                            <label for="">Token Name</label>
                            <input type="text" className="form-control"  placeholder="Token Name" value={tokenName} readOnly />
                        </div>
                        <div className="form-group">
                            <label for="address">Contract Address</label>
                            <input type="text" className="form-control" id="address"  placeholder="Contract Address" value={conAddress} readOnly />
                        </div>
                        <div className="form-group">
                            <label for="buy">Buy Price</label>
                            <input type="text" className="form-control" id="buy" onChange={(e) => setbuyPrice(e.target.value)} placeholder="Buy Price" value={buyPrice} />
                        </div>
                        <div className="form-group">
                            <label for="sell">Sell Price</label>
                            <input type="text" className="form-control" id="sell"  onChange={(e) => setsellPrice(e.target.value)} placeholder="Sell Price" value={sellPrice} />
                        </div>
                        
                        <div className="form-group">
                            <label for="daily-sell">Daily Sell Quantity</label>
                            <input type="text" className="form-control" id="daily-sell"  onChange={(e) => setdailySell(e.target.value)} placeholder="Daily Sell Quantity" value={dailySell} />
                        </div>
                        <div className="custom-control custom-switch mb-3">
                            <input type="checkbox" className="custom-control-input" id="switch" checked={sellstatus}  onClick={() => setsellstatus(sellstatus == true ? false:true)} />
                            <label className="custom-control-label" for="switch"><p className='ml-3 mt-1'>Sell Status</p></label>
                        </div>
                        <div className="custom-control custom-switch mb-3">
                            <input type="checkbox" className="custom-control-input" id="switch1" value='ON' checked={purchaseStatus} onClick={() => setpurchaseStatus(purchaseStatus == true ? false:true)}/>
                            <label className="custom-control-label" for="switch1"><p className='ml-3 mt-1'>Buy Status</p></label>
                        </div>
                        
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>    
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditSwapToken