import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { AuthorizationHeader } from './header.action';
import { startLoader, stopLoader } from './loader.action'; 


const saveWalletTransactions = (data) => {
    return {
        type : types.SAVE_WALLET_TRANSACTIONS,
        payload:data
    }
}

export const walletTransfer = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.walletTransferApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.status);
            }else{
                toast.error(res.data.status);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}
export const WalletTransactions = () => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.walletTransactionApi({}, options).then((res) => { 
            // console.log('sent data ',res.data.data.transactions)
            if(res.data.success == 1){
               dispatch( saveWalletTransactions(res.data.data.transactions));
                toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

///////////////////////////////////// p2p data ////////////////////////////////////////////////////////

const save_p2p_orders_transactions = (data) => {
    return {
        type : types.SAVE_P2P_ORDERS_TRANSACTIONS,
        payload:data
    }
}

export const p2p_orders_transactions = () => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.p2p_orders_transactions_api({}, options).then((res) => { 
            // console.log('sent data ',res.data.data.transactions)
            if(res.data.success == 1){
               dispatch( save_p2p_orders_transactions(res.data));
                // toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                // toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const save_p2p_posting_data = (data) => {
    return {
        type : types.SAVE_P2P_POSTING_DATA,
        payload:data
    }
}

export const p2p_posting_data = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.p2p_posting_data_api(data, options).then((res) => { 
            // console.log('sent data ',res.data.data.transactions)
            if(res.data.success == 1){
               dispatch( save_p2p_posting_data(res.data));
                // toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                // toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const view_save_p2p_posting_data = (data) => {
    return {
        type : types.SAVE_VIEW_P2P_POSTING_DATA,
        payload:data
    }
}

export const view_p2p_posting_data = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.view_p2p_posting_data_api(data, options).then((res) => { 
            // console.log('sent data ',res.data.data.transactions)
            if(res.data.success == 1){
               dispatch( view_save_p2p_posting_data(res.data));
                // toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                // toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

const save_P2pOrders = (data) => {
    return {
        type : types.SAVE_P2P_ORDER,
        payload:data
    }
}

export const P2pOrders_function = (data) => (dispatch,getState) => {
    try { 
        dispatch(startLoader());
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.P2pOrders_api(data, options).then((res) => { 
            // console.log('sent data ',res.data.data.transactions)
            if(res.data.success == 1){
               dispatch(save_P2pOrders(res.data));
                // toast.success(res.data.status);
                dispatch(stopLoader());
            }else{
                // toast.error(res.data.status);
                dispatch(stopLoader());
            }
        }).catch((error) => {
            console.log('Failed', error);
            
        });
    } catch (error) {
        console.log('Failed', error);
    }
}

export const P2pOrderAction = (data) => (dispatch, getState) => {
    try { 
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token); 
        return apiService.p2pOrderActonApi(data, options).then((res) => { 
            console.log(res)
            if(res.data.success == 1){
                toast.success(res.data.message);
            }else{
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log('Failed', error);
        });
    } catch (error) {
        console.log('Failed', error);
    }
}