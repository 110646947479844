import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { p2p_posting_data } from '../../../redux/actions/wallet.action';

const P2pPosting = () => {
    const dispatch = useDispatch();
    const [data ,setData] = useState();
    const p2p_trans = useSelector(state => state.wallet.p2p_posting);
    console.log('p2p_trans',p2p_trans);
    useEffect(() => {
        dispatch(p2p_posting_data());
    },[]);

    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }
    const  showData = () => {
        return p2p_trans?.my_postings?.map((val,key) => {
            return(
                <tr>
                    <th scope="row">{key+1}</th>
                    <td>{val.user_id[0].username}</td>
                    <td>{val.token_id[0].coinName}</td>
                    <td>{val.price}</td>
                    <td>{val.sell_currency}</td>
                    <td>{val.quantity}</td>
                    <td>{val.min_limit}</td>
                    <td>{val.quantity - val.sold_quantity}</td>

                    <td>{secondsToHms(val.createdAt)}</td>
                    <td><Link to={'/vendor/P2pPostingDetails/'+val._id} className='btn table-btn bg-warning'>view</Link></td>
                </tr>
            );
        })
        
    }
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > P2P Posting</h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> P2P  Posting</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">SellerId</th>
                            <th scope="col">Token Name</th>
                            <th scope="col">Price</th>
                            <th scope='col'>Sell Currency</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Min-Limit</th>
                            <th scope="col">Pending</th>
                            <th scope="col">Date</th>
                            <th scope='col'>View</th>
                            </tr>
                        </thead>
                        <tbody>
                          {showData()}
                            
                        </tbody>
                    </table>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default P2pPosting
