import React, { useEffect, useState } from 'react';
import { Container, Row, Col ,Table} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails ,getBanners , activateAccount,BinanceBalance } from '../../../redux/actions/dashboard.action';
import IndexBox from './indexBox';
import  '../../../_assets/css/pro-2.css';

const Dashboard = () => { 
    const user = useSelector(state => state.dashboard.user);
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserDetails())
        // dispatch(getBanners())
        
        // console.log('user ',user)
    }, []);
    
    return (
        <>
            <Row>
                <IndexBox></IndexBox>
            </Row>
        </>
    )
}

export default Dashboard;