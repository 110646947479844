import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails,allUsers } from '../../../redux/actions/dashboard.action';
import { delete_news, get_news } from '../../../redux/actions/swap.action';
import { makeStyles } from '@material-ui/core';
import { Pagination, Icon } from 'semantic-ui-react';
const useStyles  = makeStyles(() => ({
    
    Pagination:{
        '& .MuiPaginationItem-root':{
            color:'#000',
            display:'flex',
        li:{
            backgroundColor:'#fff',
        } ,   
        }
    }
}));
const News = () => {
    const [kycData,setkycData] = useState(); 
    const get_news_data = useSelector(state => state.swap.get_news);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(get_news({limit:5,skip:0}))
    }, [])

    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * 5);
        dispatch(get_news({ skip: pageNo, limit: 5 })); 
        setpageNum(pageNo+1)
        // console.log('page',pageNo);
    }   

    const [page,setpage] = useState(1);
    const [pageNum,setpageNum] = useState(1);


var skipdata = page-1;

    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }

    const  delete_news_fun = (val) => {
        dispatch(delete_news({id:val}))
    }
    const usersData = () =>{
        return (
            get_news_data?.record &&  get_news_data.record.length > 0 ?
                get_news_data.record.map((row,i) => {
                    return(
                        <tr>
                            <td scope="row">{i+pageNum}</td>
                            <td>{row.news}</td>
                            <td>{secondsToHms(row.createdAt)}</td>
                            <td><Link className='btn table-btn btn-success bg-success' to={{pathname:'/vendor/edit-news', params:{props:row}}}>Edit</Link> | <button className='btn table-btn btn-success bg-danger' onClick={() => delete_news_fun(row._id)}>Delete   </button></td>
                        </tr>
                    );
                })
        : ' News Not  Found.');
    }
    console.log('get_news_data',get_news_data);

  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > News</h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> News</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">News</th>
                               <th scope="col">Created At</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData()}
                        </tbody>
                    </table>   
                    <div className='pagination'>
                        <Pagination className="mx-auto"
                            defaultActivePage={1}
                            onPageChange={pageChange}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={Math.ceil(get_news_data?.total / 5)}
                         />  
                    </div>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default News