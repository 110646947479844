import react, { useEffect, useState } from 'react';
import  '../../../_assets/css/pro-2.css';
import { Link,NavLink, withRouter } from 'react-router-dom';
import { PROJECT_NAME } from "../../../_constants";
import LinkCard from './link_card';


const SideNav = () =>{
    const [activeDrop,SetactiveDrop] = useState();
    const [active,Setactive] = useState();
    const openNav = () => {
        document.getElementById('sidebar').className = 'sidebar s-left';
    }
    const closeNav = () => {
        document.getElementById('sidebar').className = 'sidebar ';
    }
    const openlink = window.location.href;
    const dropData = localStorage.getItem("drop");
    
    useEffect(() => {
        var id = openlink.substring(openlink.lastIndexOf('/') + 1);
        console.log('this is loading is ' ,id)
        Setactive(id);
        if(id != 'dashboard' ){
            if(activeDrop == undefined  ){
            //     localStorage.setItem('drop', activeDrop);
                if(id == 'users'){
                    SetactiveDrop('dropdown1');
                }else if(id == 'tempusers'){
                    SetactiveDrop('dropdown2');

                }else if(id == 'blockchains'){
                    SetactiveDrop('dropdown3');

                }else if(id == 'coins'){
                    SetactiveDrop('dropdown4');

                }else if(id == 'kyc' || id == 'kycDetail' || id == 'pending-kyc' || id =='approved-kyc' || id == 'rejected-kyc'){
                    SetactiveDrop('dropdown5');

                }else if(id == 'network' || id == 'deposit' || id == 'create-token'){
                    SetactiveDrop('dropdown6');

                }else if(id == 'allDeposit'){
                    SetactiveDrop('dropdown7');

                }else if(id == 'p2p_trans' || id == 'P2pPosting' || id == 'P2pOrders'){
                    SetactiveDrop('dropdown8');

                }else if(id == 'withdraw'){
                    SetactiveDrop('dropdown9');

                }else if(id == 'swaptoken' || id == 'swap_trans' ){
                    SetactiveDrop('dropdown10');

                }else if(id == 'create-news' || id == 'news'  ){
                    SetactiveDrop('dropdown11');

                }else if(id == 'view-banner' || id == 'banner'  ){
                    SetactiveDrop('dropdown12');

                }else if(id == 'view-trade-order' || id == 'pending-trade-order' || id == 'cancel-trade-order'  || id == 'completed-trade-order' ){
                    SetactiveDrop('dropdown13');

                }

            }
            // const dropData = localStorage.getItem("drop");
            // SetactiveDrop(dropData);
            // console.log('dropData',dropData);
            
        }
    }, [openlink,activeDrop])
    
    return(
        <>
            <div >
                <div className="logo-content ">
                    <p> {PROJECT_NAME}  </p>
                    <p  id="open"  > <p className='p-0' onClick={openNav} ><i className="fa fa-bars menu" aria-hidden="true" ></i></p><p className="nav-link" onClick={closeNav} >  <i className='far fa-hand-point-left left' ></i> </p> </p>
                </div>
                <ul className="nav-list" id="list"  > 
                    <li className="dropdown" id="dropdown" > 
                    <Link  to='/vender/dashboard'  >
                        <i className='fas fa-home'></i>
                        <span className="link-name">  DASHBOARD  </span>
                    </Link>
                        
                    </li>
                    <li className={activeDrop == 'dropdown1'? 'dropdown link-drop-2': 'dropdown'} id="dropdown1" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link to={openlink} onClick={() => {activeDrop == 'dropdown1'?  SetactiveDrop() :  SetactiveDrop('dropdown1')}}>
                            <i className="fas fa-hand-holding-usd"></i>
                            <span className="link-name"> Users </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li >  <Link to="/vendor/users" className={active == 'users'? 'a-active': ' '} id=''>Users </Link>   </li>
                                <li >  <Link to="/vendor/paid_users" className={active == 'paid_users'? 'a-active': ' '} id=''>Paid Users </Link>   </li>
                                <li >  <Link to="/vendor/today_joinings" className={active == 'today_joinings'? 'a-active': ' '} id=''> Today Joinings </Link>   </li>
                            </ol>
                        </div>
                    </li>
                    <li className={activeDrop == 'dropdown15'? 'dropdown link-drop-2': 'dropdown'} id="dropdown15" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link to={openlink} onClick={() => {activeDrop == 'dropdown15'?  SetactiveDrop() :  SetactiveDrop('dropdown15')}}>
                            <i className="fas fa-hand-holding-usd"></i>
                            <span className="link-name"> ROI Management </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li >  <Link to="/vendor/Roi/roi_list" className={active == 'users'? 'a-active': ' '} id=''>Investments </Link>   </li>
                            </ol>
                        </div>
                    </li> 
                    <li className={activeDrop == 'dropdown3'? 'dropdown link-drop-2': 'dropdown'} id="dropdown3" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link to="/vendor/reward_list" onClick={() => {activeDrop == 'dropdown3'?  SetactiveDrop() : SetactiveDrop('dropdown3')}}>
                            <i className="fas fa-user-circle"></i>
                            <span className="link-name">  Payout  </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li>  <Link to="/vendor/income" className={active == 'income'? 'a-active': ' '}> All Payout </Link>   </li>
                                <li>  <Link to="/vendor/income_type/roi_level_income/0" className={active == 'roi_level_income'? 'a-active': ' '}> Roi Level Income </Link>   </li>
                                <li>  <Link to="/vendor/income_type/income/0" className={active == 'income'? 'a-active': ' '}> Roi Income </Link>   </li>
                                <li>  <Link to="/vendor/income_type/roi_level_income/1" className={active == 'roi_level_income'? 'a-active': ' '}> Today Roi Level Income </Link>   </li>
                                <li>  <Link to="/vendor/income_type/income/1" className={active == 'income'? 'a-active': ' '}> Today Roi Income </Link>   </li>
                               <li>  <Link to="/vendor/reward_list" className={active == 'income'? 'a-active': ' '}> Reward </Link>   </li>
                            </ol>
                        </div>
                    </li> 
                     
                    <li className={activeDrop == 'dropdown5'? 'dropdown link-drop-2 d-none': 'dropdown d-none'}  id="dropdown5" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link onClick={() => {activeDrop == 'dropdown5'?  SetactiveDrop() : SetactiveDrop('dropdown5')}}>
                        <i className="fas fa-file-invoice-dollar"></i>
                            <span className="link-name">  KYC  </span>
                        </Link>
                            <div className="anydashbord">
                                <ol>
                                    <li>  <Link to="/vendor/pending-kyc"  className={active == 'pending-kyc'? 'a-active': ' '}>Pending KYC </Link>   </li>
                                    <li>  <Link to="/vendor/kycDetail"  className={active == 'kycDetail'? 'a-active': ' '}>KYC Requests</Link>   </li>
                                    <li>  <Link to="/vendor/approved-kyc"  className={active == 'approved-kyc'? 'a-active': ' '}>Approved KYC </Link>   </li>
                                    <li>  <Link to="/vendor/rejected-kyc"  className={active == 'rejected-kyc'? 'a-active': ' '}>Rejected KYC </Link>   </li>

                                </ol>
                            </div>
                    </li>
                   
                    <li to="/vendor/allDeposit" className={activeDrop == 'dropdown7'? 'dropdown link-drop-2': 'dropdown'}  id="dropdown7" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link onClick={() => {activeDrop == 'dropdown7'?  SetactiveDrop() : SetactiveDrop('dropdown7')}}>
                        <i className="fas fa-file-invoice-dollar"></i>
                            <span className="link-name">  Deposit Transactions  </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li>  <Link to="/vendor/allDeposit"  className={active == 'allDeposit'? 'a-active': ' '}>All Deposit </Link>   </li>

                            </ol>
                        </div>
                    </li> 
                    <li className={activeDrop == 'dropdown9'? 'dropdown link-drop-2': 'dropdown'}  id="dropdown9" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link to="/vendor/withdraw/investment_withdraw" onClick={() => {activeDrop == 'dropdown9'?  SetactiveDrop() : SetactiveDrop('dropdown9')}}>
                        <i className="fas fa-file-invoice-dollar"></i>
                            <span className="link-name">  Withdraw  </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li>  <Link to="/vendor/withdraw/income_withdraw"  className={active == 'withdraw/income_withdraw'? 'a-active': ' '}>Income Withdraw </Link>   </li>
                                <li>  <Link to="/vendor/withdraw/investment_withdraw"  className={active == 'withdraw/investment_withdraw'? 'a-active': ' '}>Investment Withdraw </Link>   </li>

                            </ol>
                        </div>
                    </li>
                     
                    

                    <li className={activeDrop == 'dropdown13'? 'dropdown link-drop-2 d-none': 'dropdown d-none'}  id="dropdown13" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link onClick={() => {activeDrop == 'dropdown13'?  SetactiveDrop() : SetactiveDrop('dropdown13')}}>
                        <i className="fas fa-file-invoice-dollar"></i>
                            <span className="link-name">  Trade Orders  </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li>  <Link to="/vendor/view-trade-order"  className={active == 'view-trade-order'? 'a-active': ' '}>All Orders</Link> </li>
                                <li>  <Link to="/vendor/pending-trade-order"  className={active == 'pending-trade-order'? 'a-active': ' '}>Pending Orders</Link>   </li>
                                <li>  <Link to="/vendor/cancel-trade-order"  className={active == 'cancel-trade-order'? 'a-active': ' '}>Cancelled Orders</Link>   </li>
                                <li>  <Link to="/vendor/completed-trade-order"  className={active == 'completed-trade-order'? 'a-active': ' '}>Completed Orders</Link>   </li>

                            </ol>
                        </div>
                    </li>
                    <li to="/vendor/fund/history" className={activeDrop == 'dropdown14'? 'dropdown link-drop-2': 'dropdown'} id="dropdown14" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link onClick={() => {activeDrop == 'dropdown14'?  SetactiveDrop() :  SetactiveDrop('dropdown14')}}>
                            <i className="fas fa-hand-holding-usd"></i>
                            <span className="link-name"> Fund Management </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li >  <Link to="/vendor/fund/history" className={active == 'users'? 'a-active': ' '} id=''>Fund Usage </Link>   </li>
                                <li >  <Link to="/vendor/fund/send_fund" className={active == 'fund'? 'a-active': ' '} id=''>Send Fund Personally </Link>   </li>
                                <li >  <Link to="/vendor/fund/converted_fund_history" className={active == 'fund'? 'a-active': ' '} id=''>Converted Fund History </Link>   </li>
                            </ol>
                        </div>
                    </li>

                    <li className={activeDrop == 'dropdown11'? 'dropdown link-drop-2  d-none': 'dropdown d-none'}  id="dropdown11" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link onClick={() => {activeDrop == 'dropdown11'?  SetactiveDrop() : SetactiveDrop('dropdown11')}}>
                        <i className="fas fa-file-invoice-dollar"></i>
                            <span className="link-name">  News  </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li>  <Link to="/vendor/create-news"  className={active == 'create-news'? 'a-active': ' '}>Create News</Link> </li>
                                <li>  <Link to="/vendor/news"  className={active == 'news'? 'a-active': ' '}>News</Link>   </li>

                            </ol>
                        </div>
                    </li>
                    <li className={activeDrop == 'dropdown12'? 'dropdown link-drop-2  d-none': 'dropdown  d-none'}  id="dropdown12" >
                        <i className='fas fa-caret-right dropicon'></i>
                        <Link onClick={() => {activeDrop == 'dropdown12'?  SetactiveDrop() : SetactiveDrop('dropdown12')}}>
                        <i className="fas fa-file-invoice-dollar"></i>
                            <span className="link-name">  Banner  </span>
                        </Link>
                        <div className="anydashbord">
                            <ol>
                                <li>  <Link to="/vendor/banner"  className={active == 'banner'? 'a-active': ' '}>Create Banner</Link> </li>
                                <li>  <Link to="/vendor/view-banner"  className={active == 'view-banner'? 'a-active': ' '}>Banners</Link>   </li>

                            </ol>
                        </div>
                    </li>
                </ul> 

                </div>
        </>
    );
}

export default SideNav;