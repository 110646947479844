import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { createToken } from '../../../redux/actions/dashboard.action';
import { edit_news } from '../../../redux/actions/swap.action';

const EditNews = (props) => {
    const  dispatch = useDispatch();
    const [newsRec,setnewsRec] = useState(props?.location?.params?.props);
    const [getnews,setNews] = useState();



    const handleSubmitForm = (e) => { 
        e.preventDefault();
        var data ={
            id:newsRec._id,
            news:getnews
        }
        dispatch(edit_news(data,props.history));
      }
console.log(props);
    
    return (
        <div>
            <div className="row pra">
                <div>
                    <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > Edit News</h4>
                </div>
            </div>
            <div className="table-area" > 
                <div className="card border-0 bg-transparent"> 
                <h4 className='my-3'> Edit News</h4>
                    <div className='table-responsive col-md-6'>
                        <form onSubmit={handleSubmitForm}>
                            <div className="form-group">
                                <label for="coinName">News</label>
                                <textarea id="w3review" name="w3review" rows="4" cols="50" onChange={(e) => {setNews(e.target.value)}}>{newsRec?.news}</textarea>
                            </div>
                            
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditNews;