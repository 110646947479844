import React, { useEffect, useState } from 'react';
import  '../../../_assets/css/tables.css';
import { getUserDetails } from '../../../redux/actions/dashboard.action';
import { walletTransfer ,WalletTransactions} from '../../../redux/actions/wallet.action';
import {IncomeTransactions} from '../../../redux/actions/income.action';
import { useDispatch, useSelector } from "react-redux"; 
import { toast } from '../../../components/toast';
import { currency } from "../../../_constants";
import { Pagination, Icon } from 'semantic-ui-react';
const Income  = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.dashboard.user);
    const income_transactions = useSelector(state => state.income.income_transactions); 
    const limit = 50;
    const [skip,setSkip] = useState(0);
    console.log('table page',user);
    // window.location.reload();
    useEffect(() => {
         dispatch(IncomeTransactions({limit:limit,skip:skip})); 
         
    },[]);

    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * limit); 
        setSkip(pageNo)
        dispatch(IncomeTransactions({ skip: pageNo, limit: limit })); 
    }   
    

    return(
        <>
            <div className="">

        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" > Income Transaction</h4>
                </div>
        </div>


        <div className="table-area">
            <div className="card border-0 table-responsive">

                <h4> Income Transaction</h4>
                <table className="table table-borderless">
                     <thead className="text-light">
                        <tr>
                        <th>#</th>
                        <th>User ID</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Description</th> 
                        <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                    {
                        income_transactions?.transactions && income_transactions.transactions.length > 0 ?
                        income_transactions.transactions.map((row, i) => {
                                    return (
                                        <tr>
                                            <td>{skip + i  + 1}</td>
                                            <td>{row.user[0].user_id}</td>
                                            <td>{currency}{row.amount}</td>
                                            <td>{row.type}</td>
                                            <td>{row.description}</td>   
                                            <td>{row.createdAt}</td>   
                                        </tr>
                                    )
                                })
                            : 'No Income Transations Found.' 
                    } 
                    </tbody>
                </table>
                <Pagination className="mx-auto"
                        defaultActivePage={1}
                        onPageChange={pageChange}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={Math.ceil(income_transactions.record_count / limit)}
                />    
            </div>
        </div>
                 
        </div>    
    
        </>
    );
}

export default Income;