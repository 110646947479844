import * as types from '../actions/_types';

const initialState = {
    pacakges:{}, 
    
};

export default function packagedata(state = initialState, action) {
    // console.log('my persist called ' ,action.payload)
    // console.log('type page data',action.payload);
    if(action.type == '[USER] SAVE_LOGIN_USER_DETAIL'){
        console.log(types.SAVE_PACKAGE)
    
    }
  switch (action.type) {
    
    case types.SAVE_PACKAGE:
      return { ...state,pacakges: action.payload };   
    default:
      return state;
  }
}

