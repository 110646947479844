import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Form } from "react-bootstrap";
import logo from "../../../_assets/images/logo.png";
import { FaRegUserCircle, FaUnlockAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_NAME } from "../../../_constants";
import ResetPasswordForm from "./ResetPasswordForm";
import { resetPassword } from "../../../redux/actions/user.action";

const ResetPassword = ({ history }) => {
  const dispatch = useDispatch();
  const forgotPass = useSelector(state => state.persist.forgotPass);

  const handleResetPassword = (values) => {
    dispatch(resetPassword(values, history));
  }

  useEffect(() => {
    if (forgotPass.passwordResetToken.length == 0) {
      // history.push('/');
    }
  }, []);

  return (
    <>
      <div className="bground wrapper login-page style-3" id="top">
      <div className="cp-container">
        <div className="col-md-12 card card-body p-0 ">
          <div className="row">
            <div className="col-md-6 bg-right text-center">
                <div className="register-logo"> 
                    <a href="/">
                      <img className="light" src={logo} style={{maxWidth : '125px',borderRadius:'10px',marginBottom: '20px'}}/>
                    </a>
                    <h5 className="page-title text-white">Welcome To {PROJECT_NAME}</h5>
                </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <div className="cp-body p-3 pb-4">
                  <h5 className="head-title">Reset password</h5>
                    <div className="panel panel-primary">
                    <ResetPasswordForm onSubmit={handleResetPassword} />
                      
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    </>
  );
};

export default ResetPassword;
