import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { view_p2p_posting_data } from '../../../redux/actions/wallet.action';

const P2pPostingDetails = ({ match: { params: { id } } }) => {
    const p2p_trans = useSelector(state => state.wallet.view_p2p_posting);

    const dispatch = useDispatch();
    const [data ,setData] = useState();
    const [posting,setposting] =useState(p2p_trans?.posting);
    console.log('p2p_trans',posting);
    useEffect(() => {
        dispatch(view_p2p_posting_data({posting_id:id}));
    },[]);
    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }
    const  showData = () => {
        return p2p_trans?.orders?.map((val,key) => {
            return(
                <tr>
                    <th scope="row">{key+1}</th>
                    <td >{val.buyer_id[0].username}</td>
                    <td >{val.buyer_id[0].firstname ? val.buyer_id[0].firstname+' '+ val.buyer_id[0].lastname : ' '}</td>
                    <td>{val.quantity}</td>
                    <td>{val.UserPaymentMethodID[0]?.PaymentMethodFields.map(field => {
                        return(<><span>{field.field.replace("_",' ').toUpperCase() }: {field.value}</span><br/></>)
                    })}</td>
                    <td>{secondsToHms(val.createdAt)}</td>
                    <td>{val.status}</td>
                    
                    {/* <td><button>view</button></td> */}
                </tr>
            );
        })
        
    }
  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > P2P Posting Details</h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> P2P  Posting Details</h4>
                 <div className=' single-table-data-main card'>
                    <div className='single-table-data'>
                        <p>Seller Details :</p>
                        <p>{posting}</p>
                    </div> 
                    <div className='single-table-data'>
                        <p>Token Name :</p>
                        <p> </p>
                    </div> 
                    <div className='single-table-data'>
                        <p>Price :</p>
                        <p>{p2p_trans?.posting?.price} {p2p_trans?.posting?.sell_currency}</p>
                    </div> 
                    <div className='single-table-data'>
                        <p>Seller Currency :</p>
                        <p>{p2p_trans?.posting?.sell_currency}</p>
                    </div> 
                    <div className='single-table-data'>
                        <p>Quantity :</p>
                        <p>{p2p_trans?.posting?.quantity}</p>
                    </div> 
                    <div className='single-table-data'>
                        <p>Min-Limit :</p>
                        <p>{p2p_trans?.posting?.min_limit}</p>
                    </div> 
                    <div className='single-table-data'>
                        <p>Sold Quanitty :</p>
                        <p>{p2p_trans?.posting?.sold_quantity}</p>
                    </div> 
                    <div className='single-table-data'>
                        <p>Available Quantity :</p>
                        <p>{p2p_trans?.posting?.quantity - p2p_trans?.posting?.sold_quantity}</p>
                    </div> 
                    <div className='single-table-data'>
                        <p>Date :</p>
                        <p>{secondsToHms(p2p_trans?.posting?.createdAt)}</p>
                    </div> 
                    <div className=' single-table-data'>
                        <p>Payment Methods :</p>
                        <p>{' '}</p>
                    </div>    
                </div>
                <div className='table-responsive mt-5'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Buyer Details</th>
                            <th scope="col">Buyer Name</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">PaymentMethod</th>
                            <th scope='col'>Date</th>
                            <th scope="col">Status</th>
                            {/* <th scope='col'>View</th> */}
                            </tr>
                        </thead>
                        <tbody>
                          {showData()}
                            
                        </tbody>
                    </table>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default P2pPostingDetails
