import React, { useEffect, useState } from 'react'
// import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { allDepositDetail } from '../../../redux/actions/deposit.action';
import { makeStyles } from '@material-ui/core';
import { Pagination, Icon } from 'semantic-ui-react';
const useStyles  = makeStyles(() => ({
    
    Pagination:{
        '& .MuiPaginationItem-root':{
            color:'#000',
            display:'flex',
        li:{
            backgroundColor:'#fff',
        } ,   
        }
    }
}));
const AllDeposit = () => {
    const dispatch = useDispatch();

    const pageChange = (e, data) => {
        let page = data.activePage;
        let pageNo = page === 1 ? 0 : ((page - 1) * 20);
        dispatch(allDepositDetail({ skip: pageNo, limit: 20 })); 
    }   



        const [page,setpage] = useState(1);
        var skipdata = page-1;
        useEffect(() =>{
            dispatch(allDepositDetail({limit:20,skip:0}))
            
        },[page]);

    const allTrx = useSelector(state => state.deposit.all_trx);
    console.log('allTrx',allTrx)
    const classes = useStyles();
console.log('page',page);
  return (
    <div> 
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3 text-dark'> All Deposit Deatils</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Hash</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Action</th>
                            <th scope="col">Time</th>
                            </tr>
                        </thead>
                        <tbody>
                       { allTrx?.transactions?.map((val,key) => {
                            return(
                                    <tr>
                                        <th scope="row">{key+1}</th>
                                        <td>
                                           Hash : {val.hash}<br/>
                                           From : {val.from}<br/>
                                           To : {val.to}<br/>
                                        </td>
                                        <td>$ {val?.value}</td>
                                        <td>
                                            Gas Fee : {val?.GasDepositStatus == true ? 'Done' : 'pending'}<br/>
                                            Debit Status : {val?.AdminDebitStatus == true ? 'Done' : 'pending'}<br/>
                                        </td>
                                        <td>{val?.createdAt}</td>
                                    </tr>
                            );
                       })}
                        
                            
                        </tbody>
                    </table>   
                    <div className='pagination'>
                    <Pagination className="mx-auto"
                        defaultActivePage={1}
                        onPageChange={pageChange}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={Math.ceil(allTrx.transactionrecords / 20)}
                />  
                    </div>         
          </div>
            </div>
        </div>
    </div>
  )
}

export default AllDeposit