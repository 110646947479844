import * as types from '../actions/_types';

const initialState = {
    roi_transactions:{
      records : [],
      
    }, 
    
};

export default function roi(state = initialState, action) {
  switch (action.type) {
    
    case types.SAVE_ROI_TRANSACTIONS:
      return { ...state,roi_transactions: action.payload };   
    case types.SAVE_REWARD_TRANSACTIONS:
      return { ...state,reward_transactions: action.payload };   
    default:
      return state;
  }
}

