import * as types from '../actions/_types';

const initialState = {
    wallet_amount : 0, 
    wallet_transactions:{}, 
    p2p_trans:[],
    p2p_posting:[],
    view_p2p_posting:[],
    p2p_orders:[],
};

export default function wallet(state = initialState, action) {
   
  switch (action.type) {
    
    case types.SAVE_WALLET_TRANSACTIONS:
      return { ...state, wallet_transactions: action.payload }; 
    case types.SAVE_P2P_ORDERS_TRANSACTIONS:
      return{ ...state, p2p_trans : action.payload }; 
    case types.SAVE_P2P_POSTING_DATA:
      return{...state ,p2p_posting : action.payload}; 
    case types.SAVE_VIEW_P2P_POSTING_DATA:
      return{...state,view_p2p_posting: action.payload};
    case types.SAVE_P2P_ORDER:
      return{...state,p2p_orders: action.payload};        
    default:
      return state;
  }
}

