import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { depositDetail } from '../../../redux/actions/dashboard.action';

const Deposit = () => {
    const deposit = useSelector(state => state.kycData.deposit);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(depositDetail())
        
    }, [])
    console.log('deposit',deposit)
    const depositData = () =>{
        return (
            deposit?.deposit_tokens &&  deposit.deposit_tokens.length > 0 ?
            deposit.deposit_tokens.map((row,i) => {
                return(
                    <tr>
                        <th scope="row">{i+1}</th>
                        <td>{row.coinName}</td>
                        <td>{row.type}</td> 
                        <td>{row.confirmations}</td>
                        <td><img src={row.image} width='50' /> </td>
                        {/* <td><Link to={'/vendor/edit-token/'+row._id} className='btn table-btn btn-success bg-success'>View More</Link></td>  */}

                        <td><Link to={{pathname:'/vendor/edit-token/'+row._id , state:{props:row}}} className='btn table-btn btn-success bg-success'>View More</Link></td> 
 
                    </tr>
                );
            })
        : 'Network Not Found.');
    }
    return (
        <div>
            <div className="row pra">
                <div>
                    <i className="fa fa-cube" ></i>
                </div>
                <div className="pra-text" >
                    <h4 className="mb-0" >  Tokens Details</h4>
                </div>
            </div>
            <div className="table-area"> 
                <div className='d-flex justify-content-between'>
                    <h4 className='my-3'> Tokens Details</h4>
                    <Link to='/vendor/create-token' className='btn btn-sm'>Create Token</Link>
                </div>
                <div className="card border-0 bg-transparent"> 
                    <div className='table-responsive'>
                        <table className="table table-hover text-white">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Coin Name</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Confirmations</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {deposit?.deposit_tokens && deposit != undefined ? depositData() : ' '}
                            </tbody>
                        </table>   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Deposit