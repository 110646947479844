import react from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"; 
import { Link,NavLink, withRouter } from 'react-router-dom';
import { getUserDetails } from '../../../redux/actions/dashboard.action';
import { PROJECT_NAME } from "../../../_constants";

const IndexBox = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.dashboard.user);
    const income_type_transactions = useSelector(state => state.income.income_type_transactions); 
    // console.log('User',user);
    useEffect(() => {
        
        dispatch(getUserDetails())

    }, [])


    return(
        <>
             {/* <div className="box"> */}


                <div className="row">
                    <div className="col-4 mt-2">
                        <div className="card border border-rounded  text-white bg-primary mb-3"> 
                            <div className='card-header'>
                                <h4 className='text-center'> Users </h4>
                            </div>
                            <div className='card-body'>
                                <h6 className='pl-2'>Total : {user?.users}</h6>
                                <h6 className='pl-2'>Paid <b>{user.paid_users}</b></h6>
                                <h6 className='pl-2'>Today Joined     <b>{user.today_joinngs}</b></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 mt-2">
                        <div className="card border border-rounded text-white bg-secondary mb-3"> 
                            <div className='card-header'>
                                <h4 className='text-center'> Investment </h4>
                            </div>
                            <div className='card-body'>
                                <h5>Total : ${user?.total_investment}</h5>
                                <h6>Today <b>$ {user.today_investment}</b></h6> 
                            </div>
                        </div>
                    </div>
                    <div className="col-4 mt-2">
                        <div className="card border border-rounded text-white bg-success mb-3"> 
                            <div className='card-header'>
                                <h4 className='text-center'> Withdraw </h4>
                            </div>
                            <div className='card-body'>
                                <h5>Total : ${user?.total_withdraw_amount}</h5>
                                <h6>Payable Amount :<b>$ {user.total_payable_amount}</b></h6> 
                            </div>
                        </div>
                    </div>
                    <div className="col-4 mt-2">
                        <div className="card border border-rounded text-white bg-danger mb-3"> 
                            <div className='card-header'>
                                <h4 className='text-center'> Deposit </h4>
                            </div>
                            <div className='card-body'>
                                <h5>REAL USDT : ${user?.total_deposited_usdt}</h5>
                                <h6>ADMIN Virtual USDT : <b>$ {user.admin_emergency_fund}</b></h6> 
                                <h6>Converted USDT :<b>$ {user.converted_fund}</b></h6> 
                            </div>
                        </div>
                    </div> 

                    <div className="col-4 mt-2">
                        <div className="card border border-rounded text-white bg-danger mb-3"> 
                            <div className='card-header'>
                                <h4 className='text-center'> Today Payout </h4>
                            </div>
                            <div className='card-body'>
                                <h5>ROI Income : ${user?.today_roi_income}</h5>
                                <h6>Roi Level Income: <b>$ {user.today_roi_level_income}</b></h6> 
                                <h6>Total :<b>$ {user.today_roi_level_income + user?.today_roi_income }</b></h6> 
                            </div>
                        </div>
                    </div> 
                    
                </div>
                {/* </div> */}
        </>
    );
}

export default IndexBox;