import * as types from '../actions/_types';

const initialState = {
  all_trx: [],
  
};

export default function deposit(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_ALL_DEPOSIT_TRX:
      return { ...state, all_trx: action.payload };
   
    default:
      return state;
  }
}

