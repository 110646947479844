import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { deleteBannerAction, getBanners } from '../../../redux/actions/dashboard.action';
const BannerTable = () => {
    const [kycData,setkycData] = useState(); 
    const [idDelete,setidDelete] = useState(' '); 

    const user = useSelector(state => state.dashboard.user);
    const banners = useSelector(state => state.dashboard.banners);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBanners({skip:0,limit:15}));
    }, [idDelete]);
    // console.log('banners',banners);
    function secondsToHms(c_date) {
        const isoStr = c_date;
        const date1 = new Date(isoStr); 
        const timestamp = date1.getTime();
        var date = new Date(timestamp);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+ " "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }

    const deleteBanner = (_id) => {
        dispatch(deleteBannerAction({id: _id}));
        setidDelete(_id);
    }
    const records = () =>{
        return (
            banners &&  banners.length > 0 ?
                banners.map((row,i) => {
                    return(
                        <tr>
                            <th scope="row">{i+1}</th>
                            <td>{row.title}</td>
                            <td><img src={row.image} width='200' /></td>
                            <td>{secondsToHms(row.createdAt)}</td>
                            <td><button className='btn btn-sm table-btn bg-danger' onClick={() => deleteBanner(row._id)} >Delete</button></td>
                        </tr>
                    );
                })
        : ' Users Not  Found.');
    }

  return (
    <div>
        <div className="row pra">
            <div>
                <i className="fa fa-cube" ></i>
            </div>
            <div className="pra-text" >
                <h4 className="mb-0" > All Banners</h4>
            </div>
        </div>
        <div className="table-area"> 
            <div className="card border-0 bg-transparent"> 
            <h4 className='my-3'> Banners Deatils</h4>
                <div className='table-responsive'>
                    <table className="table table-hover text-white">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Image</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records()}
                        </tbody>
                    </table>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default BannerTable