import * as types from '../actions/_types';

const initialState = {
  swap_list:[],
  user_swap_list:[],
  swap_trans:[],
  swap_single_trans: [],
  get_news:[],
};

export default function swap(state = initialState, action = {}) {
  switch (action.type) {
    
    case types.SAVE_SWAP_TOKEN_LIST:
      return { ...state, swap_list: action.payload }; 
    case types.SAVE_SWAP_USER_TOKEN:
      return {...state , user_swap_list: action.payload}; 
    case types.SAVE_SWAP_TOKEN_LIST_TRANSACTION:
      return{...state , swap_trans: action.payload};  
    case types.SAVE_SWAP_SINGLE_TRANSACTION:
      return{...state , swap_single_trans: action.payload};  
    case types.SAVE_NEWS:
      return{...state, get_news: action.payload};  
    default:
      return state;
  }
}

