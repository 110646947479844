import React from 'react';
import { Container } from 'react-bootstrap';
import { PROJECT_NAME } from "../../_constants";

 const Footer = () => {
   
  return (     
    <div className="footer">
    <p> Copyright <a href="#"> {PROJECT_NAME} </a> & {new Date().getFullYear()} </p>
    <p className="mr-5"> . </p>
  </div>
  )
};

export default Footer;