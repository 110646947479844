import { toast } from '../../components/toast';
import * as types from './_types';
import * as apiService from '../../Services/api/api.service';
import { startLoader, stopLoader } from './loader.action';
import { AuthorizationHeader, AuthorizationHeaderImage } from './header.action';
import { reset } from 'redux-form';

export const saveAllDeposit = (data) => {
    return {
        type: types.SAVE_ALL_DEPOSIT_TRX,
        payload: data
    }
}



export const allDepositDetail = (data) => (dispatch, getState) => {
    try {
        
        dispatch(startLoader());            
        const { persist: { token } } = getState();
        const options = AuthorizationHeader(token);
        return apiService.getAllDispatch(data, options).then((res) => {
            res = res.data;
            dispatch(saveAllDeposit(res));
            dispatch(stopLoader());
        }).catch((error) => {
            console.log('Failed', error);
            dispatch(stopLoader());
        });
    } catch (error) {
        console.log('Failed', error);
        dispatch(stopLoader());
    }
}
