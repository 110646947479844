import * as types from '../actions/_types';

const initialState = {
    income_transactions:{},
    income_type_transactions:{},  
    
};

export default function income(state = initialState, action) {
    // console.log('my persist called ' ,action.payload)
    if(action.type == '[USER] SAVE_LOGIN_USER_DETAIL'){
        console.log(types.SAVE_INCOME_TRANSACTIONS)
    
    }
  switch (action.type) {
    
    case types.SAVE_INCOME_TRANSACTIONS:
      return { ...state, income_transactions: action.payload };   
    
      case types.SAVE_INCOME_TYPE_TRANSACTIONS:
      return { ...state,income_type_transactions: action.payload };   
    default:
      return state;
  }
}


